<div *ngIf="categories | async as categoriesList" id="container">
  <div id="top-banner">
    <a *ngIf="middleBannerLink" target="_blank" href="{{middleBannerLink}}" (click)="onCategorySelect('ad-button', 'Ad Button')">
      <img src="{{middleBanner}}" alt="Middle banner image"/>
    </a>
    <img *ngIf="!middleBannerLink" src="{{middleBanner}}" alt="Middle banner image"  (click)="onCategorySelect('ad-banner', 'ad-banner')"/>
  </div>
  <a class="category" id="hotline" [routerLink]="['/category', hotlineCategoryKey]" (click)="onCategorySelect(hotlineCategoryKey, hotlineCategoryName)">
    <i class="material-icons">{{hotlineCategoryIcon}}</i>
    <br>
    <p id="hotlineName">{{hotlineCategoryName}}</p>
  </a>
  <div class="category" id="middle-banner">
    <a *ngIf="middleBannerLink" target="_blank" href="{{middleBannerLink}}">
      <img src="{{middleBanner}}" alt="Middle banner image"/>
    </a>
    <img *ngIf="!middleBannerLink" src="{{middleBanner}}" alt="Middle banner image"/>
  </div>
  <a class="category" id="call211" routerLink="/program/{{hotlineImageProgramId}}" (click)="onCategorySelect('211', '211')">
    <img src="{{hotlineImage}}" alt="211 image">
    <div id="call211icon">
      <i class="material-icons" [ngStyle]="{'color': baseColor}">phone</i>
      <br>
      <p class="name">211</p>
    </div>
  </a>
  <div *ngFor="let category of categoriesList">
    <a [routerLink]="['/category', category.key]" (click)="onCategorySelect(category.key, category.Name)">
      <div class="category" [ngStyle]="{'color': baseColor}">
        <i class="material-icons">{{category.Icon?.toLowerCase()}}</i>
        <br>
        <p class="name">{{category.Name}}</p>
      </div>
    </a>
  </div>
</div>
<div *ngIf="showLogoGrid" class="logo-grid">
  <div class="logo-container">
    <div class="logo-item">
      <img src="../assets/images/col.svg" alt="City of Lincoln Nebraska logo">
    </div>
    <div class="logo-item">
      <img src="../assets/images/dpl.svg" alt="Don't Panic Labs logo">
    </div>
    <div class="logo-item">
      <img src="../assets/images/ll.svg" alt="Leadership Lincoln logo">
    </div>
    <div class="logo-item">
      <img src="../assets/images/cfpin.svg" alt="Center for People in Need logo">
    </div>
  </div>
</div>
