export enum Location {
    NotSet,
    Lincoln,
    Kearney,
    Sandhills,
    Sarpy,
    Southeast
}

export const LocationCodeMapper = {
    [Location.NotSet]: '',
    [Location.Lincoln]: 'lincolnne',
    [Location.Kearney]: 'centralne',
    [Location.Sandhills]: 'sandhillsne',
    [Location.Sarpy]: 'sarpyne',
    [Location.Southeast]: 'southeastne'
};

export const LocationNameMapper = {
    [Location.NotSet]: '',
    [Location.Lincoln]: 'Lincoln NE',
    [Location.Kearney]: 'Central NE',
    [Location.Sandhills]: 'Sandhills NE',
    [Location.Sarpy]: 'Sarpy NE',
    [Location.Southeast]: 'Southeast NE'
};

export const LocationLatLongMapper = {
  [Location.NotSet]: {},
  [Location.Lincoln]: {lat: 40.8136,  lng: -96.7026},
  [Location.Kearney]: {lat: 40.8109,  lng: -98.6802},
  [Location.Sandhills]: {lat: 41.5803,  lng: -99.3466},
  [Location.Sarpy]: {lat: 41.0744, lng: -96.1951},
  [Location.Southeast]: {lat: 40.778446, lng: -97.28285 }
};
