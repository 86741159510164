import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CategoryAccessorService } from 'src/app/Services/category-accessor.service';
import { OrganizationAccessorService } from 'src/app/Services/organization-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { ProgramAccessorService } from 'src/app/Services/program-accessor.service';
import { Program } from '../../Models/program.model';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProgramListItem } from 'src/app/Models/program-list-item';
import { ThemeAccessorService } from 'src/app/Services/theme-accessor.service';

@Component({
  selector: 'app-new-program-list',
  templateUrl: './new-program-list.component.html',
  styleUrls: ['./new-program-list.component.sass'],
})
export class NewProgramListComponent implements OnInit {
  translationSet: string[][] = [
    [
      'ما هو الجديد',
      'لا شيء جديد الآن ، يرجى التحقق مرة أخرى لاحقًا للحصول على التحديثات.',
      'جديد',
      'اسم',
      'منظمة',
      'الفئة',
      'التحديث الاخير',
    ],
    [
      "What's New",
      'Nothing new right now, please check back later for updates.',
      'New',
      'NAME',
      'ORGANIZATION',
      'CATEGORY',
      'LAST UPDATED',
    ],
    [
      'Qué hay de nuevo',
      'No hay nada nuevo en este momento, vuelva a consultar más tarde para ver las actualizaciones.',
      'Nuevo',
      'NOMBRE',
      'ORGANIZACIÓN',
      'CATEGORÍA',
      'ÚLTIMA ACTUALIZACIÓN',
    ],
    [
      'Maxaa Cusub',
      'Ma jiraan wax cusub hadda, fadlan dib kala soco wixii ku soo kordha.',
      'Cusub',
      'MAGAC',
      'URURKA',
      'QAYBTA',
      'WAQTIGII UGU DANBEEYAY',
    ],
    [
      'Có gì mới',
      'Không có gì mới ngay bây giờ, vui lòng kiểm tra lại sau để cập nhật.',
      'Mới',
      'TÊN',
      'CƠ QUAN',
      'THỂ LOẠI',
      'CẬP NHẬT MỚI NHẤT',
    ],
    [
      'Що нового',
      'Наразі нічого нового, будь ласка, перевірте пізніше, щоб отримати оновлення.',
      'нове',
      `ІМ'Я`,
      'ОРГАНІЗАЦІЯ',
      'КАТЕГОРІЯ',
      'ОСТАННЄ ОНОВЛЕННЯ'
    ],
    [
      'چه جدید',
      'در حال حاضر چیز جدیدی نیست، لطفاً بعداً برای به روز رسانی ها بررسی کنید.',
      'جدید',
      'نام',
      'سازمان',
      'دسته بندی',
      'آخرین به روز رسانی'
    ],
    [
      'څه خبری دي',
      'همدا اوس هیڅ نوی ندی، مهرباني وکړئ د تازه معلوماتو لپاره وروسته بیا وګورئ.',
      'نوی',
      'نوم',
      'سازمان',
      'کټګوري',
      'وروستی تازه شوی'
    ],
    [
      'چ شتێكی نوێ هەیە',
      'لە ئێستادا هیچ شتێکی نوێ نییە، تکایە دواتر سەیری بکە بۆ نوێکردنەوە.',
      'نوێ',
      'ناو',
      'ڕێکخراو',
      'جۆر',
      'دوایین نوێکردنەوە'
    ],
    [
      'Çi Nû ye',
      'Niha tiştek nû nîne, ji kerema xwe paşê ji bo nûvekirinan vegere.',
      'Nşh',
      'NAV',
      'SAZÛMAN',
      'LIQ',
      'DAWÎ NÛKIRIN'
    ]
  ];
  displayColumns: string[] = [
    'New',
    'Name',
    'Organization',
    'Category',
    'Date',
  ];
  dataSource: MatTableDataSource<ProgramListItem>;
  minTimeValue = 1420095600000;
  lang: string;
  wantedDaysAgo = 45;
  maxDaysAgo = 90;
  maxPrograms = 50;
  baseColor: string;

  constructor(
    private router: Router,
    private preferencesService: PreferencesService,
    private organizationAccessor: OrganizationAccessorService,
    private categoryAccessor: CategoryAccessorService,
    private programAccessor: ProgramAccessorService,
    private themeAccessor: ThemeAccessorService
  ) {}

  async ngOnInit() {
    this.lang = this.preferencesService.getLang();
    this.themeAccessor.getThemeElement('baseColor').subscribe((element) => {
      this.baseColor = element;
    });

    await this.fetchProgramListData();
  }

  private getDisplayName(program: Program): string {
    return Program.getDisplayName(
      this.organizationAccessor,
      program,
      60,
      this.lang
    );
  }

  private async getCategoryName(program: Program) {
    const cat = await this.categoryAccessor
      .getCategoryById(program.CategoryId, this.lang)
      .pipe(take(1))
      .toPromise();
    return cat.Name;
  }

  private async fetchProgramListData() {
    let programs = await this.programAccessor
      .getPrograms(this.lang)
      .pipe(take(1))
      .toPromise();

    programs.sort((a: Program, b: Program) => {
      const aTime: number =
        a.Timestamp && a.Timestamp.Time ? a.Timestamp.Time : this.minTimeValue;
      const bTime: number =
        b.Timestamp && b.Timestamp.Time ? b.Timestamp.Time : this.minTimeValue;
      return bTime - aTime;
    });

    const today = new Date();
    let minDate = today.setDate(today.getDate() - this.wantedDaysAgo);
    programs = programs.slice(0, this.maxPrograms);

    // Check for any programs to be shown with first date
    if (programs[0].Timestamp && programs[0].Timestamp.Time < minDate) {
      minDate = today.setDate(today.getDate() - this.maxDaysAgo);
    }

    const items: ProgramListItem[] = [];
    for (let program of programs) {
      if (program.Timestamp && program.Timestamp.Time < minDate) {
        // Exit early because programs are sorted and next programs are too early
        break;
      }
      const catName = await this.getCategoryName(program);
      const item: ProgramListItem = {
        name: this.getDisplayName(program),
        organization: Program.getOrgName(
          this.organizationAccessor,
          program,
          this.lang
        ),
        category: catName,
        timestamp: program.Timestamp,
        key: program.key,
        // Only new programs have empty string changes
        isNew: program.Timestamp && program.Timestamp.Changes === '',
      };
      items.push(item);
    }

    this.dataSource = new MatTableDataSource<ProgramListItem>(items);
  }

  goToProgram(item: ProgramListItem) {
    this.router.navigate([`/program/${item.key}`]);
  }

  getTranslationSet(): string[] {
    switch (this.lang) {
      case 'ar':
        return this.translationSet[0];
      case 'en':
        return this.translationSet[1];
      case 'es':
        return this.translationSet[2];
      case 'so':
        return this.translationSet[3];
      case 'vi':
        return this.translationSet[4];
      case 'uk':
        return this.translationSet[5];
      case 'prs':
        return this.translationSet[6];
      case 'ps':
        return this.translationSet[7];
      case 'ku':
        return this.translationSet[8];
      case 'kmr':
        return this.translationSet[9];
      default:
        // Return English
        this.translationSet[1];
    }
  }
}
