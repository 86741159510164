import { coordinate } from './coordinate';
import { OrganizationAccessorService } from '../Services/organization-accessor.service';
import { Timestamp } from './timestamp';

export class Program {
  Address: string;
  Call: string;
  CategoryId: string;
  Description: string;
  Email: string;
  Image: string;
  OrganizationId: string;
  SubcategoryId: string;
  SubcategoryIds: string[];
  Summary: string;
  Website: string;
  Coordinates: coordinate;
  key?: string;
  Timestamp: Timestamp;

  //Program to get the related organization's name of a program
  static getOrgName(organizationAccessor: OrganizationAccessorService, program: Program, language?: string): string {
    //sets language to english if no translated language
    if (!language) language = 'en';
    if (language == 'en') {
      //if lang is eng just gets org easily
      let org = organizationAccessor.getCachedOrganizations().filter(organization => organization.key === program.OrganizationId)[0];
      //uses ternary to make sure the organization actually exists (if not it can cause entire pages to not load)
      let name = (org ? org.Name.trim() : "No organization");
      return name;
    } else {
      let name;
      let tempOrg = organizationAccessor.getCachedOrganizations(language).filter(organization => organization.key === program.OrganizationId)[0];
      if (!tempOrg) {
        tempOrg = organizationAccessor.getCachedOrganizations().filter(organization => organization.key === program.OrganizationId)[0];
        //makes sure organization actually exists (if not it can cause errors when programs are loading)
        name = (tempOrg ? tempOrg.Name.trim() : "No organization");
      } else {
        name = tempOrg.Name.trim();
      }
      return name;
    }
  }
  static getDisplayName(organizationAccessor: OrganizationAccessorService, program: Program, maxLength: number, language?: string): string {
    let cachedOrganizations = organizationAccessor.getCachedOrganizations();
    if (!language) language = 'en';
    let organizationName;
    if (language == 'en') {
      const organization = cachedOrganizations.find(organization => organization.key === program.OrganizationId);
      if(organization)
        organizationName = organization.Name.trim();
    }else {
      let tempOrg = organizationAccessor.getCachedOrganizations(language).find(organization => organization.key === program.OrganizationId);
      const foundOrganization = (tempOrg ? tempOrg : cachedOrganizations.find(organization => organization.key === program.OrganizationId));
      if(foundOrganization)
        organizationName = foundOrganization.Name.trim();
      return program.Summary || program.Description || 'No Name';
    }

    if (program.Summary) return `${organizationName}: ${program.Summary}`;
    if (program.Description) return `${organizationName}: ${(program.Description.length > maxLength) ? (program.Description.substring(0, maxLength + 1)) + '...' : (program.Description)}`;
    return organizationName;
  }
}
