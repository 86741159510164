import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { FormControl, Validators } from '@angular/forms';
import { Language } from 'src/app/Models/language.model';

@Component({
  selector: 'app-set-language',
  templateUrl: './set-language.component.html',
  styleUrls: ['./set-language.component.sass'],
})
export class SetLanguageComponent implements OnInit {
  public baseColor = '#2373b4';
  public languageFormControl: FormControl;
  public languages: Language[] = [];
  public logoLocation = '../assets/images/MyLNK-Logo-White.svg';

  constructor(
    private activatedRoute: ActivatedRoute,
    private preferencesService: PreferencesService,
    private router: Router
  ) {}

  public async ngOnInit() {
    const language = this.preferencesService.getLang();

    if (language) {
      this.activatedRoute.queryParams.subscribe((s) => {
        if (s['redirectUrl']) {
          const redirectUrl = s['redirectUrl'];

          this.router.navigate([redirectUrl]);
        } else {
          this.router.navigate(['home']);
        }
      });
    }

    this.languageFormControl = new FormControl('', Validators.required);

    const result = await this.preferencesService.getLanguages();
    const values: Language[] = [];
    for (let r in result) {
      values.push(result[r]);
    }
    this.languages = values;
  }

  public setLanguage() {
    const language = this.languageFormControl.value;
    this.preferencesService.setLang(language);
  }
}
