export class ProgramRequestData {
  isNew: boolean;
  id?: string;
  name?: string;
  description?: string;
  organization?: string;
  category?: string;
  phoneNumber?: string;
  address?: string;
  website?: string;
  subcategory?: string;
}
