import { Component, Input, OnInit } from '@angular/core';
import { DetailView } from '../../Models/detailView.model';
import {ThemeAccessorService} from '../../Services/theme-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.sass']
})
export class DetailViewComponent implements OnInit {
  @Input() detailView: DetailView;

  language: string;
  baseColor: string;
  descLang = '';
  assocLang = '';
  missLang = '';

  constructor(private preferencesService: PreferencesService, private themeAccessor: ThemeAccessorService) {}

  ngOnInit() {
    this.themeAccessor.getThemeElement('baseColor').subscribe(element => {
      this.baseColor = element;
    });
    this.language = this.preferencesService.getLang();
    switch (this.language) {
      case ('ar'):
        this.descLang = 'وصف';
        this.assocLang = 'البرامج المرتبطة';
        this.missLang = 'لا اسم أو وصف';
        break;
      case ('es'):
        this.descLang = 'Descripción';
        this.assocLang = 'Programas asociados';
        this.missLang = 'Sin nombre ni descripción';
        break;
      case ('vi'):
        this.descLang = 'Sự miêu tả';
        this.assocLang = 'Chương trình liên kết';
        this.missLang = 'Không có tên hoặc mô tả';
        break;
      case ('uk'):
        this.descLang = 'Oпис';
        this.assocLang = 'Супутні програми';
        this.missLang = 'Без назви чи опису';
        break;
      case 'prs':
        this.descLang = 'توضیحات';
        this.assocLang = 'برنامه های مرتبط';
        this.missLang = 'بدون نام و توضیحات';
        break;
      case 'ps':
        this.descLang = 'تفصیل';
        this.assocLang = 'مربوط پروګرامونه';
        this.missLang = 'نوم او وضاحت نشته';
        break;
      case 'ku':
        this.descLang = 'وەسف';
        this.assocLang = 'بەرنامە پەیوەندیدارەکان';
        this.missLang = 'بێ ناو و وەسف';
        break;
      case 'kmr':
        this.descLang = 'Terîf';
        this.assocLang = 'Bernameyên têkildar';
        this.missLang = 'Na nav û şirove';
        break;
      default:
        this.descLang = 'Description';
        this.assocLang = 'Associated Programs';
        this.missLang = 'No name or description';
    }
  }

}
