<div [ngStyle]="{ 'background-color': baseColor }" class="container">
  <img class="logo" [src]="logoLocation" alt="MyLink logo" />

  <p class="subtitle">
    Please select a language
  </p>

  <div class="options-container">
    <mat-form-field class="opaque-form-field">
      <mat-select aria-label="Select language" [formControl]="languageFormControl">
        <mat-option *ngFor="let language of languages" [value]="language.code"
          >{{ language.nativeName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      mat-raised-button
      (click)="setLanguage()"
      [disabled]="!languageFormControl.valid"
    >
      Go
    </button>
  </div>
</div>
