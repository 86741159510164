import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';
import { Breadcrumb } from '../../Models/breadcrumb.model';
import { ThemeAccessorService } from '../../Services/theme-accessor.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent implements OnInit {
  isProgramPage = this.router.url.includes('/program/');
  breadcrumbs: Breadcrumb[] = [];

  baseColor: string;
  constructor(
    private themeAccessor: ThemeAccessorService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.breadcrumbService.breadcrumbsObservable.subscribe(x => {
      if (x) {
        this.breadcrumbs = x;
      }
    });
    this.baseColor = await this.themeAccessor.getSingleThemeElement('baseColor');

    this.router.events.subscribe(x => {
      this.isProgramPage = this.router.url.includes('/program/');
    });
  }

  followBreadcrumb(index: number) {
    this.breadcrumbService.spliceCrumbs(index);
  }
}
