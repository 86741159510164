<div class="breadcrumb">
  <span *ngFor="let breadcrumb of breadcrumbs, let i = index" [ngStyle]="{'color': baseColor}">
    <p><a (click)="followBreadcrumb(i)" routerLink="{{breadcrumb.route}}" [queryParams]="breadcrumb.fromCategories ? {'frmCats': '1'} : ''">{{breadcrumb.Name}}</a></p>
    <i class="material-icons">navigate_next</i>
  </span>
</div>
<h1 id="page_title" *ngIf="!isProgramPage" [ngStyle]="{'color': baseColor}">
  <i class="inline-icon material-icons">{{breadcrumbs[breadcrumbs.length-1].Icon}}</i>
  {{breadcrumbs[breadcrumbs.length-1].Name}}
</h1>

