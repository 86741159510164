<app-breadcrumb></app-breadcrumb>
<div id="container">
  <mat-expansion-panel class='result' *ngFor='let subcategory of subcategoriesCombined | async' [ngStyle]="{'color': baseColor}">
    <mat-expansion-panel-header (click)="onSubcategorySelect(subcategory.key, subcategory.Name)">
      <mat-panel-title>
        <div>{{subcategory?.Name}}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
      <div class="programContainer" *ngFor="let program of programMap[subcategory.key]" [queryParams]="{frmCats:'1'}">
        <a class="programText" [routerLink]="['/program', program.key]">
          {{(program.Summary || 'No name available')}}
        </a>
      </div>
  </mat-expansion-panel>
  <mat-expansion-panel class='result' *ngIf="otherPrograms" [ngStyle]="{'color': baseColor}" [expanded]="expandMisc">
    <mat-expansion-panel-header *ngIf="otherPrograms.length">
        <mat-panel-title>
        <a>Additional {{categoryName}} Resources</a>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="programContainer" *ngFor="let program of otherPrograms" [queryParams]="{frmCats:'1'}">
      <a class="programText" [routerLink]="['/program', program.key]">
        {{(program.Summary || 'No name available')}}
      </a>
    </div>
  </mat-expansion-panel>
</div>
