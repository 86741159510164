import { Injectable } from '@angular/core';
import {DataAccessorService} from './data-accessor.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {ObjectCache} from '../Models/objectCache.model';
import {Program} from '../Models/program.model';
import {Subcategory} from '../Models/category.model';
import {Observable} from 'rxjs';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class SubcategoryAccessorService extends DataAccessorService<Subcategory>{

  constructor(private db: AngularFireDatabase, private ps: PreferencesService) {
    super(db, `entities/${ps.getLocation()}/Data/${ps.getLang()}/Categories`, new ObjectCache<Subcategory>());
  }

  getSubcategoryByIds(categoryId: string, subcategoryId: string, lang?: string): Observable<Subcategory> {
    return super.getObjectById(`${categoryId}/Subcategories/${subcategoryId}`, lang);
  }

  getSubcategoriesById(categoryId: string, lang?: string): Observable<Subcategory[]> {
    return super.getList(`entities/${this.ps.getLocation()}/Data/${this.ps.getLang()}/Categories/${categoryId}/Subcategories`, lang);
  }

}
