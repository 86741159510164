import { Injectable } from '@angular/core';
import {Organization} from '../Models/organization.model';
import {Observable} from 'rxjs';
import {DataAccessorService} from './data-accessor.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {ObjectCache} from '../Models/objectCache.model';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})

export class OrganizationAccessorService extends DataAccessorService<Organization>{

  constructor(private db: AngularFireDatabase, private ps: PreferencesService) {
    super(db, `entities/${ps.getLocation()}/Data/${ps.getLang()}/Organizations`, new ObjectCache<Organization>());
  }

  getCachedOrganizations(lang?: string): Organization[] {
    return super.getCachedObjects(lang);
  }

  getOrganizationById(id: string, lang?: string): Observable<Organization> {
    return super.getObjectById(id, lang);
  }

  getOrganizations(lang?: string): Observable<Organization[]> {
    return super.getObjects(lang);
  }
}
