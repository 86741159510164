<div id="detail-view-content">
  <h3 class="name" [ngStyle]="{'color': baseColor}">{{detailView?.name ? detailView?.name : detailView?.displayName}}</h3>
  <br>
  <span *ngIf="detailView?.phone" class="info-icon-area">
    <a>
      <i class="material-icons" [ngStyle]="{'color': baseColor}">call</i><p>{{detailView.phone}}</p>
    </a>
  </span>
  <span *ngIf="detailView?.website" class="info-icon-area">
    <a target="_blank" href="{{detailView.website}}">
      <i class="material-icons" [ngStyle]="{'color': baseColor}">web</i><p>{{detailView.website}}</p>
    </a>
  </span>
  <span *ngIf="detailView?.address" class="info-icon-area">
    <a>
      <i class="material-icons" [ngStyle]="{'color': baseColor}">location_on</i><p>{{detailView.address}}</p>
    </a>
  </span>
  <span *ngIf="detailView?.description" class="description">
    <span><strong [ngStyle]="{'color': baseColor}">{{descLang}}</strong></span>
    <span class="descriptionText">{{detailView.description}}</span>
  </span>
  <span *ngIf="detailView?.associatedPrograms" class="associated-programs-container">
    <span><strong>{{assocLang}}</strong></span>
    <span class="associated-programs">
      <a *ngFor="let program of detailView.associatedPrograms | async" [routerLink]="['/program/', program.key]">
        <div class="associated-program" ngClass="{{!program.Summary && !program.Description ? 'missing' : ''}}">

          <span *ngIf="!detailView.associatedProgramSubcategories[program.key]">
            <div class="associated-programs-breadcrumb">
              {{detailView.associatedProgramCategories[program.key]?.Name}}
            </div>
            <br>
          </span>

          <span *ngFor="let sub of detailView.associatedProgramSubcategories[program.key]">
            <div class="associated-programs-breadcrumb">
              {{detailView.associatedProgramCategories[program.key]?.Name}}
              <span *ngIf="sub">
                <mat-icon class="arrowIcon">arrow_forward_ios</mat-icon>
                {{sub.Name}}
              </span>
            </div>
            <br>
          </span>

          {{program.Summary ? program.Summary : (program.Description ? program.Description.substring(0, 100) : missLang)}}
        </div>
      </a>
    </span>
  </span>
</div>
