import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GeocodeService} from '../../Services/geocode.service';
import {Program} from '../../Models/program.model';
import {ProgramAccessorService} from '../../Services/program-accessor.service';
import {OrganizationAccessorService} from '../../Services/organization-accessor.service';
import {ThemeAccessorService} from '../../Services/theme-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-full-map-view',
  templateUrl: './full-map-view.component.html',
  styleUrls: ['./full-map-view.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullMapViewComponent implements OnInit {

  staticsEn = ['Find programs by quadrant','None','Northwest','Northeast','Southwest','Southeast','Find programs by address','Distance (miles)','Address','Quadrant', 'Map', 'Food', 'Health', 'Other'];
  staticsAr = ['البحث عن البرنامج ، مرتبة حسب المنطقة','صلاة العصر','الشمال الغربي','شمالي شرقي','جنوب غرب','الجنوب الشرقي','البحث عن البرامج حسب الموقع','المسافة (مايلز)','عنوان الشخص','ربع دائرة', 'خريطة','الغذاء', 'الصحة' , 'آخر'];
  staticsEs = ['Buscar programas por cuadrante','Nada','Noroeste','Noreste','Sur oeste','Sureste','Buscar programas por dirección','Distancia (millas)','Dirección','Cuadrante', 'Mapa', 'Víveres', 'Salud', 'Otro'];
  staticsVi = ['Tìm chương trình theo vùng','Chẳng một ai','Tây Bắc','Đông bắc','Tây nam','Đông Nam','Tìm kiếm chương trình theo địa chỉ','Khoảng cách (dặm)','Địa chỉ nhà','Góc phần tư', 'Bản đồ', 'Thực phẩm', 'Sức khỏe', 'Khác'];
  staticsSo = ['Ku hel barnaamijyada afar meelood','Midna','Waqooyi Galbeed','Waqooyi bari','Koonfur Galbeed','Koonfur-bari','Barnaamijyada ku hel cinwaan ahaan','Fogaanta (maylal)','Cinwaanka','Afar geesle', 'Khariidad', 'Cuntada', 'Caafimaadka', 'Kale'];
  staticsUk = ['Знайти програми по квадранту','Жодного','Північний захід','Північний схід','На південний захід','Південний схід','Пошук програм за адресою','Відстань (милі)','Адреса','Квадрант','Карта','Xарчування',`Здоров'я`,'Інший'];
  staticsPrs = ['یافتن برنامه ها توسط چهار راه','هیچ کدام','شمال غربی','شمال شرقی','جنوب غربی','جنوب شرقی','یافتن برنامه ها از طریق آدرس','فاصله (مایل)','آدرس','چهار راهی','نقشه','غذا','بهداشت و درمان','دیگر'];
  staticsPs = ['د کواډرینټ لخوا پروګرامونه ومومئ','هیڅ نه','شمال لویدیځ','شمال ختیځ','سویل لویدیز','جنوب ختیځ','د ادرس په واسطه پروګرامونه ومومئ','واټن (میل)','پته','کواډرنټ','نقشه','خواړه','روغتیا','نور'];
  staticsKu = ['بەرنامەکان بەپێی چوارگۆشە بدۆزەرەوە','هیچ','باکووری ڕۆژئاوا','باکووری ڕۆژهەڵات','باشووری ڕۆژئاوا','باشووری ڕۆژهەڵات','بەرنامەکان بەپێی ناونیشان بدۆزەرەوە','مەودا (میل)','ناونیشان','چوارگۆشە','نەخشە','خواردن','تەندروستی','ئی تر'];
  staticsKmr = ['Bernameyên li gorî çargoşe bibînin','Netû','Bakurê rojavayê','Bakurrojhilat','Southwest','Southeast','Bernameyên bi navnîşan bibînin','Dûr (mil)','Navnîşan','Quadrant','Qert','Xûrek','Tendûrûstî','Yên din'];
  statics = [];

  lang = '';

  blueMarker = '../../assets/images/blueMarker.png';
  pinkMarker = '../../assets/images/pinkMarker.png';
  orangeMarker = '../../assets/images/orangeMarker.png';
  greenMarker = '../../assets/images/greenMarker.png';

  baseColor: string;

  initialMapZoom = 9;

  houseAddress: string;
  houseLocation = null;

  allMarkers = [];

  protected map: google.maps.Map;
  food = false;
  health = false;
  other = false;
  healthCategoryId: string;
  foodCategoryId: string;

  constructor(
    private geocodeService: GeocodeService,
    private preferenceService: PreferencesService,
    private organizationAccessor: OrganizationAccessorService,
    private preferencesService: PreferencesService,
    private programAccessor: ProgramAccessorService,
    private ref: ChangeDetectorRef,
    private themeAccessor: ThemeAccessorService
  ) {}

  async ngOnInit() {
    this.lang = this.preferencesService.getLang();

    this.themeAccessor.getThemeElement('baseColor').subscribe((element) => {
      this.baseColor = element;
    });

    // selects proper translation set
    switch (this.lang) {
      case 'vi':
        this.statics = this.staticsVi;
        break;
      case 'es':
        this.statics = this.staticsEs;
        break;
      case 'ar':
        this.statics = this.staticsAr;
        break;
      case 'so':
        this.statics = this.staticsSo;
        break;
      case 'uk':
        this.statics = this.staticsUk;
        break;
      case 'prs':
        this.statics = this.staticsPrs;
        break;
      case 'ps':
        this.statics = this.staticsPs;
        break;
      case 'ku':
        this.statics = this.staticsKu;
        break;
      case 'kmr':
        this.statics = this.staticsKmr;
        break;
      default:
        this.statics = this.staticsEn;
    }

    this.foodCategoryId = await this.themeAccessor
      .getThemeElement('foodCategoryId')
      .pipe(take(1))
      .toPromise();

    this.healthCategoryId = await this.themeAccessor
      .getThemeElement('healthCategoryId')
      .pipe(take(1))
      .toPromise();
    this.placeMarkers();
  }

  // initializes map library
  mapReady(map) {
    this.map = map;

    this.map.setCenter(this.preferenceService.getLocationLatLong());
    this.map.setZoom(this.initialMapZoom);
  }

  private placeMarkers() {
    this.programAccessor.getPrograms().subscribe(programs => {
      this.programAccessor.getPrograms(this.lang).subscribe(programsTr => {
        programs.forEach(program => {
          if (program.Coordinates) {
            // loads and places translated programs, then any english ones
            const translation = programsTr.filter( (programTr) => programTr.key === program.key);
            const nameHolder =
              translation.length ?
              Program.getDisplayName(this.organizationAccessor, translation[0], 150) :
              Program.getDisplayName(this.organizationAccessor, program, 150);
            const marker = {
              name: nameHolder,
              location: program.Coordinates,
              address: program.Address,
              visible: false,
              key: program.key,
              type: program.CategoryId === this.foodCategoryId ? 'food' : (program.CategoryId === this.healthCategoryId ? 'health' : 'other'),
            };
            this.allMarkers.push(marker);
          }
        });
      });
    });
  }

  addressEntered(value) {
    if (value) {
      this.houseAddress = value;
      this.geocodeService.geocodeNebraskaAddress(value).subscribe(location => {
        this.map.setCenter(location);
        this.map.setZoom(this.milesToZoomLevel(1));
        this.houseLocation = location;
        this.ref.detectChanges();
      });
    } else {
      this.map.setCenter(this.preferenceService.getLocationLatLong());
      this.map.setZoom(this.initialMapZoom);
      this.houseLocation = null;
    }
  }

  milesToZoomLevel(miles: number) {
    return Math.floor(18.5 - Math.log2(miles * 1609.34 / 141.0621525));
  }

}
