import { Injectable } from '@angular/core';
import {DataAccessorService} from './data-accessor.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {ObjectCache} from '../Models/objectCache.model';
import {Observable} from 'rxjs';
import {Category} from '../Models/category.model';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryAccessorService extends DataAccessorService<Category>{

  constructor(private db: AngularFireDatabase, private pf: PreferencesService) {
    super(db, `entities/${pf.getLocation()}/Data/${pf.getLang()}/Categories`, new ObjectCache<Category>());
  }

  getCachedCategories(lang?: string): Category[] {
    return super.getCachedObjects(lang);
  }

  getCategories(lang?: string): Observable<Category[]> {
    return super.getObjects(lang);
  }

  getCategoryById(id: string, lang?: string): Observable<Category> {
    return super.getObjectById(id, lang);
  }

}
