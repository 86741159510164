import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProgramRequest } from '../Models/programRequest.model';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  emailUrl: string = environment.functionsUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  sendProgramRequest(location: string, programRequest: ProgramRequest) {
    const path = `${this.emailUrl}/sendEmail`;
    const result = this.http.post(
      path,
      { location: location, programRequest: programRequest },
      this.httpOptions
    );
    // This is needed for the request to actually process
    result.subscribe();
  }
}
