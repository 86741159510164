import { Component, Input, OnInit } from '@angular/core';
import { Schedule } from '../../Models/schedule.model';
import { PreferencesService } from 'src/app/Services/preferences.service';

@Component({
  selector: 'app-calendar-widget',
  templateUrl: './calendar-widget.component.html',
  styleUrls: ['./calendar-widget.component.sass']
})
export class CalendarWidgetComponent implements OnInit {
  @Input() schedules: Schedule[];

  scheduledItems = 'Scheduled Items';
  cycle = 'Cycle';
  day = 'Day';
  time = 'Time';

  constructor(private preferencesService: PreferencesService) {}

  ngOnInit() {
    switch (this.preferencesService.getLang()) {
      case 'es':
        this.scheduledItems = 'Artículos programados';
        this.cycle = 'Cyclo';
        this.day = 'Día';
        this.time = 'Hora';
        break;
      case 'vi':
        this.scheduledItems = 'Mục theo lịch trình';
        this.cycle = 'Chu kỳ';
        this.day = 'ngày';
        this.time = 'Thời gian';
        break;
      case 'ar':
        this.scheduledItems = 'العناصر المجدولة';
        this.cycle = 'دورة';
        this.day = 'يوم';
        this.time = 'زمن';
        break;
      case 'uk':
        this.scheduledItems = 'Заплановані елементи';
        this.cycle = 'Цикл';
        this.day = 'День';
        this.time = 'час';
        break;
      case 'prs':
        this.scheduledItems = 'موارد برنامه ریزی شده';
        this.cycle = 'چرخه';
        this.day = 'روز';
        this.time = 'زمان';
        break;
      case 'ps':
        this.scheduledItems = 'مهالویش شوي توکي';
        this.cycle = 'سایکل';
        this.day = 'ورځ';
        this.time = 'وخت';
        break;
      case 'ku':
        this.scheduledItems = 'بابەتە بەرنامە بۆ داڕێژراوەکان';
        this.cycle = 'سووڕ';
        this.day = 'ڕۆژ';
        this.time = 'کات';
        break;
      case 'kmr':
        this.scheduledItems = 'Items Scheduled';
        this.cycle = 'Zîvirok';
        this.day = 'Roj';
        this.time = 'Dem';
        break;
    }
  }

}
