import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageGuard implements CanActivate {
  constructor(
    private preferencesService: PreferencesService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const language = next.queryParams['language'];
    if (language) {
      return true;
    }

    if (!this.preferencesService.getLang()) {
      const currentUrl = next.url.map((m) => m.path).join('/');

      if (currentUrl.includes('home') || currentUrl.includes('set-language')) {
        this.router.navigate(['/set-language'], {
          queryParams: { currentUrl },
        });
        return false;
      }

      // Default to English for outside pages
      this.preferencesService.setLangSilent('en');
    }

    return true;
  }
}
