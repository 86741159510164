<app-breadcrumb></app-breadcrumb>
<div id="container">
  <div id="info-area">
    <app-detail-view [detailView]="detailView"></app-detail-view>
    <p (click)="openDialog()" class="link">
      Report Incorrect or New Information
    </p>
  </div>
  <div id="map-and-cal">
    <app-map-widget [address]="detailView?.address"></app-map-widget>
    <app-calendar-widget [schedules]="schedules"></app-calendar-widget>
  </div>
</div>
