import {Component, Input} from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map-widget',
  templateUrl: './map-widget.component.html',
  styleUrls: ['./map-widget.component.sass']
})

export class MapWidgetComponent {
  apiKey = environment.googleMapsApiKey;
  _address: string;

  @Input('address')
  set address(value: string) {
    if(value)
      //removes characters that google maps has trouble parsing
      value = value.replace('#',' number ').replace('&',' and ').replace('  ', ' ').trim();
    this._address = value;
  }

  get address(): string {
    return this._address;
  }

  constructor() {}


}
