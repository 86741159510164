import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProgramRequestData } from '../../Models/programRequestModalData.model';
import { CategoryAccessorService } from 'src/app/Services/category-accessor.service';
import { Category } from 'src/app/Models/category.model';
import { OrganizationAccessorService } from 'src/app/Services/organization-accessor.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProgramRequest } from 'src/app/Models/programRequest.model';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { EmailService } from 'src/app/Services/email.service';
import { ThemeAccessorService } from 'src/app/Services/theme-accessor.service';

@Component({
  selector: 'app-program-request',
  templateUrl: './program-request.component.html',
  styleUrls: ['./program-request.component.sass'],
})
export class ProgramRequestComponent implements OnInit {
  categories: Category[];
  filteredOrganizations: Observable<string[]>;
  requestForm: FormGroup;
  textValues: string[];
  private errorMessageSet = {
    ar: ['الحقل مطلوب', 'يجب أن يكون بريدًا إلكترونيًا صالحًا'],
    en: ['Field is required', 'Must be a valid email'],
    es: ['El campo es obligatorio', 'Debe ser un correo electrónico válido'],
    so: [
      'Foomka foomka ayaa loo baahan yahay',
      'Waa inay noqotaa emayl sax ah',
    ],
    vi: ['Trường biểu mẫu là bắt buộc', 'Phải là email hợp lệ'],
  };
  private foodCategoryId: string;
  private healthCategoryId: string;
  private lang: string;
  private location: string;
  private organizations: string[];
  private translationSet = {
    ar: [
      'طلب برنامج جديد',
      'طلب تعديل البرنامج',
      'اسم الطالب',
      'البريد الإلكتروني الطالب',
      'إسم البرنامج',
      'منظمة',
      'عنوان',
      'موقع إلكتروني',
      'التنسيق المقترح (xxx) xxx-xxxx',
      'رقم الهاتف',
      'بريد إلكتروني',
      'فئة',
      'تصنيف فرعي',
      'وقت البدء',
      'وقت النهاية',
      'وصف',
      'معلومات اخرى',
      'تم إجراء أية تغييرات. يرجى إجراء التغييرات لتمكين الزر إرسال. ',
      'يلغي',
      'إرسال',
    ],
    en: [
      'Request a New Program',
      'Request Program Edit',
      'Requester Name',
      'Requester Email',
      'Program Name',
      'Organization',
      'Address',
      'Website',
      'Suggested format (xxx)-xxx-xxxx',
      'Phone Number',
      'Email',
      'Category',
      'Subcategory',
      'Start Time',
      'End Time',
      'Description',
      'Other Info',
      'No changes have been made. Please make changes to enable the Submit button.',
      'Cancel',
      'Submit',
    ],
    es: [
      'Solicitar un nuevo programa',
      'Solicitar edición de programa',
      'Nombre solicitante',
      'Correo electrónico del solicitante',
      'Nombre del programa',
      'Organización',
      'Habla a',
      'Sitio web',
      'Formato sugerido (xxx) xxx-xxxx',
      'Número de teléfono',
      'Correo electrónico',
      'Categoría',
      'Subcategoría',
      'Hora de inicio',
      'Hora de finalización',
      'Descripción',
      'Otra información',
      'No se han hecho cambios. Realice cambios para habilitar el botón Enviar. ',
      'Cancelar',
      'Enviar',
    ],
    so: [
      'Codso Barnaamij Cusub',
      'Codsi Wax Ka Beddel Barnaamijka',
      'Magaca Dib-u-habeynta',
      'Emailka Dib u Dira',
      'Magaca Barnaamijka',
      'Urur',
      'Cinwaanka',
      'Website',
      'Qaab la soo jeediyey (xxx) xxx-xxxx',
      'Lambarka taleefanka',
      'Email',
      'Qayb',
      'Subcategory',
      'Waqtiga Bilowga',
      'Waqtiga Dhamaadka',
      'Sharaxaad',
      'Macluumaad kale',
      'Wax isbeddel ah lama samayn. Fadlan isbeddel samee si aad awood ugu yeelatid badhanka Gudbinta. ',
      'Baajiso',
      'Gudbi',
    ],
    vi: [
      'Yêu cầu một chương trình mới',
      'Yêu cầu Chỉnh sửa Chương trình',
      'Tên Người Yêu cầu',
      'Email của người yêu cầu',
      'Tên chương trình',
      'Cơ quan',
      'Địa chỉ',
      'Trang mạng',
      'Định dạng được đề xuất (xxx) xxx-xxxx',
      'Số điện thoại',
      'E-mail',
      'Thể loại',
      'Danh mục con',
      'Thời gian bắt đầu',
      'Thời gian kết thúc',
      'Sự miêu tả',
      'Thông tin khác',
      'Không có thay đổi nào được thực hiện. Vui lòng thực hiện các thay đổi để kích hoạt nút Gửi. ',
      'Hủy bỏ',
      'Gửi đi',
    ],
  };

  constructor(
    public dialogRef: MatDialogRef<ProgramRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProgramRequestData,
    private categoryAccessor: CategoryAccessorService,
    private emailService: EmailService,
    private organizationAccessor: OrganizationAccessorService,
    private preferencesService: PreferencesService,
    private themeAcessor: ThemeAccessorService
  ) {
    // Disable closing by clicking off screen
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.setFormGroup();
    this.location = this.preferencesService.getLocation();
    this.lang = this.preferencesService.getLang() || 'en';
    this.textValues = this.translationSet[this.lang];

    if (this.data.isNew) {
      this.categoryAccessor.getCategories(this.lang).subscribe((cats) => {
        this.categories = cats.sort((a: Category, b: Category) =>
          a.Name.localeCompare(b.Name)
        );
      });

      this.themeAcessor.getThemeElement('foodCategoryId').subscribe((id) => {
        this.foodCategoryId = id;
      });
      this.themeAcessor.getThemeElement('healthCategoryId').subscribe((id) => {
        this.healthCategoryId = id;
      });

      this.organizations = this.organizationAccessor
        .getCachedOrganizations()
        .map((o) => o.Name)
        .sort((a: string, b: string) => a.localeCompare(b));

      // Autocomplete filter
      this.filteredOrganizations = this.requestForm
        .get('organization')
        .valueChanges.pipe(
          startWith(''),
          map((value) => this.filter(this.organizations, value))
        );
    }
  }

  private filter(options: string[], value: string): string[] {
    const filterValue = value.toLowerCase();

    return options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private getValueOrDefault(value: string): string {
    if (value === null || value === undefined) {
      return '';
    }

    return value;
  }

  private setFormGroup(): void {
    if (this.data.isNew) {
      this.requestForm = new FormGroup({
        address: new FormControl(
          this.getValueOrDefault(this.data.address),
          Validators.required
        ),
        category: new FormControl({ Name: '' }, Validators.required),
        description: new FormControl(
          this.getValueOrDefault(this.data.description),
          Validators.required
        ),
        endTime: new FormControl(''),
        email: new FormControl('', Validators.email),
        name: new FormControl(
          this.getValueOrDefault(this.data.name),
          Validators.required
        ),
        organization: new FormControl(
          this.getValueOrDefault(this.data.organization),
          Validators.required
        ),
        otherInfo: new FormControl(''),
        phoneNumber: new FormControl(
          this.getValueOrDefault(this.data.phoneNumber),
          Validators.required
        ),
        requesterName: new FormControl(''),
        requesterEmail: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        startTime: new FormControl(''),
        subcategory: new FormControl(''),
        website: new FormControl(
          this.getValueOrDefault(this.data.website),
          Validators.required
        ),
      });
    } else {
      this.requestForm = new FormGroup({
        address: new FormControl(this.getValueOrDefault(this.data.address)),
        description: new FormControl(
          this.getValueOrDefault(this.data.description)
        ),
        name: new FormControl(this.getValueOrDefault(this.data.name)),
        phoneNumber: new FormControl(
          this.getValueOrDefault(this.data.phoneNumber)
        ),
        requesterName: new FormControl(''),
        requesterEmail: new FormControl('', [
          Validators.required,
          Validators.email,
        ]),
        website: new FormControl(this.getValueOrDefault(this.data.website)),
      });
    }
  }

  getErrorMessage(control: string) {
    return this.requestForm.get(control).hasError('required')
      ? this.errorMessageSet[this.lang][0]
      : this.requestForm.get(control).hasError('email')
      ? this.errorMessageSet[this.lang][1]
      : '';
  }

  getSubcategories(): string[] {
    let subcatNames = [];
    if (this.requestForm.get('category').value.Subcategories) {
      const cat: Category = this.requestForm.get('category').value;
      const subcategories = cat.Subcategories;

      // Though it says that subcategories is type Subcategory[], it is actually
      // an object with keys for each subcategory with a Name value when retrieved from firebase.
      // Thus, get its values in a way that works for both object and array types
      subcatNames = Object.values(subcategories)
        .map((x) => x.Name)
        .sort((a: string, b: string) => a.localeCompare(b));
    }

    return subcatNames;
  }

  isFoodOrHealth(): boolean {
    return (
      this.requestForm.get('category') &&
      (this.requestForm.get('category').value.key === this.foodCategoryId ||
        this.requestForm.get('category').value.key === this.healthCategoryId)
    );
  }

  hasValueChanged(): boolean {
    return (
      this.requestForm.get('name').value !== this.data.name ||
      this.requestForm.get('description').value !== this.data.description ||
      this.requestForm.get('phoneNumber').value !== this.data.phoneNumber ||
      this.requestForm.get('address').value !== this.data.address ||
      this.requestForm.get('website').value !== this.data.website
    );
  }

  submitProgramRequest(): void {
    const request: ProgramRequest = {
      isNew: this.data.isNew,
      name: this.requestForm.get('name').value,
      description: this.requestForm.get('description').value,
      phoneNumber: this.requestForm.get('phoneNumber').value,
      address: this.requestForm.get('address').value,
      website: this.requestForm.get('website').value,
      requesterName: this.requestForm.get('requesterName').value,
      requesterEmail: this.requestForm.get('requesterEmail').value,
    };

    if (this.data.isNew) {
      request.organization = this.requestForm.get('organization').value;

      if (this.requestForm.get('category').value) {
        request.category = this.requestForm.get('category').value.Name;
        if (
          this.requestForm.get('subcategory').value &&
          this.getSubcategories().includes(
            this.requestForm.get('subcategory').value
          )
        ) {
          request.subcategory = this.requestForm.get('subcategory').value;
        }
      }

      if (this.requestForm.get('email').value) {
        request.email = this.requestForm.get('email').value;
      }
      if (this.isFoodOrHealth() && this.requestForm.get('startTime').value) {
        request.startTime = this.requestForm.get('startTime').value;
      }
      if (this.isFoodOrHealth() && this.requestForm.get('endTime').value) {
        request.endTime = this.requestForm.get('endTime').value;
      }
      if (this.requestForm.get('otherInfo').value) {
        request.otherInfo = this.requestForm.get('otherInfo').value;
      }
    } else {
      request.id = this.data.id;
      request.category = this.data.category;
      request.subcategory = this.data.subcategory;
    }

    this.emailService.sendProgramRequest(this.location, request);
    this.dialogRef.close();
  }
}
