<div id="container">
  <div>
    <h1 [ngStyle]="{ color: baseColor }">{{ getTranslationSet()[0] }}</h1>
  </div>

  <div class="table-container">
    <p class="message" *ngIf="dataSource?.filteredData?.length === 0">
      {{ getTranslationSet()[1] }}
    </p>
    <mat-table
      mat-table
      [dataSource]="dataSource"
      *ngIf="dataSource?.filteredData?.length > 0"
    >
      <ng-container matColumnDef="New">
        <mat-header-cell *matHeaderCellDef>{{
          getTranslationSet()[2].toUpperCase()
        }}</mat-header-cell>
        <mat-cell *matCellDef="let program">
          <p *ngIf="program.isNew">{{ getTranslationSet()[2] }}</p>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>{{
          getTranslationSet()[3]
        }}</mat-header-cell>
        <mat-cell *matCellDef="let program">
          {{ program.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Organization">
        <mat-header-cell *matHeaderCellDef>{{
          getTranslationSet()[4]
        }}</mat-header-cell>
        <mat-cell *matCellDef="let program">{{
          program.organization
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="Category">
        <mat-header-cell *matHeaderCellDef>{{
          getTranslationSet()[5]
        }}</mat-header-cell>
        <mat-cell *matCellDef="let program">
          {{ program.category }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Date">
        <mat-header-cell *matHeaderCellDef>{{
          getTranslationSet()[6]
        }}</mat-header-cell>
        <!--Set a min value for the date value-->
        <!--Date locale information is set by the language abbreviation-->
        <mat-cell *matCellDef="let program">
          {{
            (program.timestamp?.Time > minTimeValue
              ? program.timestamp?.Time
              : minTimeValue
            ) | date: 'shortDate':undefined:this.lang
          }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayColumns"
        (click)="goToProgram(row)"
        class="data-row"
      ></mat-row>
    </mat-table>
  </div>
</div>
