<app-breadcrumb></app-breadcrumb>
<div id='container'>
  <ul>
    <li *ngFor='let program of programResults'>
      <div class='result' [ngStyle]="{'color': baseColor}">
        <a [routerLink]="['/program', program.id]" [queryParams]="{frmCats:'1'}">{{program.name}}</a>
      </div>
    </li>
  </ul>
</div>
