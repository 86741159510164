import { Component, OnInit } from '@angular/core';
import { Category } from '../../Models/category.model';
import { Breadcrumb } from '../../Models/breadcrumb.model';
import { CategoryAccessorService } from '../../Services/category-accessor.service';
import { ThemeAccessorService } from '../../Services/theme-accessor.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { Location, LocationCodeMapper } from 'src/app/Models/enums/location';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';
import { analytics, database } from 'firebase/app';

@Component({
  selector: 'app-categories-view',
  templateUrl: './categories-view.component.html',
  styleUrls: ['./categories-view.component.sass']
})
export class CategoriesViewComponent implements OnInit {

  baseColor: string;
  hotlineId: string;
  middleBanner: string;
  middleBannerLink: string;
  hotlineCategoryKey: string;
  hotlineCategoryIcon: string;
  hotlineCategoryName: string;
  public categories: Observable<Category[]>;

  hotlineImage: string;
  hotlineImageProgramId: string;
  displayLogoContainer: boolean;
  showLogoGrid: boolean;

  constructor(
    private categoryAccessor: CategoryAccessorService,
    private breadcrumbService: BreadcrumbService,
    private preferencesService: PreferencesService,
    private themeAccessor: ThemeAccessorService
  ) {}

  async ngOnInit() {
    switch (this.preferencesService.getLang()) {
      case 'vi':
        this.breadcrumbService.initCrumb([new Breadcrumb('Trang chủ', '/categories')]);
        break;
      case 'es':
        this.breadcrumbService.initCrumb([new Breadcrumb('Inicio', '/categories')]);
        break;
      case 'ar':
        this.breadcrumbService.initCrumb([new Breadcrumb('البدء', '/categories')]);
        break;
      case 'uk':
        this.breadcrumbService.initCrumb([new Breadcrumb('додому', '/categories')]);
        break;
      case 'prs':
        this.breadcrumbService.initCrumb([new Breadcrumb('صفحه اصلی', '/categories')]);
        break;
      case 'ps':
        this.breadcrumbService.initCrumb([new Breadcrumb('کور', '/categories')]);
        break;
      case 'ku':
        this.breadcrumbService.initCrumb([new Breadcrumb('ماڵەوە', '/categories')]);
        break;
      case 'kmr':
        this.breadcrumbService.initCrumb([new Breadcrumb('Xane', '/categories')]);
        break;
      default:
        this.breadcrumbService.initCrumb([new Breadcrumb('Home', '/categories')]);
    }

    [this.baseColor, this.hotlineId, this.middleBanner, this.middleBannerLink, this.hotlineImage, this.hotlineImageProgramId] = await Promise.all([
      this.themeAccessor.getSingleThemeElement('baseColor'),
      this.themeAccessor.getSingleThemeElement('hotlineId'),
      this.themeAccessor.getSingleThemeElement('middleBanner'),
      this.themeAccessor.getSingleThemeElement('middleBannerLink'),
      this.themeAccessor.getSingleThemeElement('211Image'),
      this.themeAccessor.getSingleThemeElement('211Id')
    ]);

    this.categories = this.categoryAccessor.getCategories(this.preferencesService.getLang());

    this.categories.subscribe(categories => {
      const hotlineCategory = categories.find(category => category.key.includes(this.hotlineId));
      if (hotlineCategory) {
        this.hotlineCategoryKey = hotlineCategory.key;
        this.hotlineCategoryIcon = hotlineCategory.Icon;
        this.hotlineCategoryName = hotlineCategory.Name;
      }
      this.categories = this.categories.pipe(map(categories => categories.filter(category => !category.key.includes(this.hotlineId))));
      this.categories = this.categories.pipe(map(categories => categories.sort((a, b) => a.Name > b.Name ? 1 : -1)));
    });

    this.displayLogoContainer = this.preferencesService.getLocation() === LocationCodeMapper[Location.Lincoln];
    this.showLogoGrid = this.categories && this.displayLogoContainer;
  }

  onCategorySelect(categoryKey: string, categoryName: string) {
    analytics().logEvent('category_selected', { categoryKey, categoryName });
    const ref = database().ref('categorySelections');
    ref.push({ categoryKey, categoryName, timestamp: Date.now() });
  }

}
