import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from '../Models/breadcrumb.model';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumbs: Breadcrumb[] = [];
  private breadcrumbsBehaviorSubject = new BehaviorSubject(this.breadcrumbs);
  public breadcrumbsObservable = this.breadcrumbsBehaviorSubject.asObservable();

  public initCrumb(newCrumb: Breadcrumb[]) {
    this.breadcrumbs = newCrumb;
    this.breadcrumbsBehaviorSubject.next(this.breadcrumbs);
  }

  addCrumb(crumb: Breadcrumb) {
    const indexOfCrumb = this.breadcrumbs
      .map(x => x.route)
      .indexOf(crumb.route);
    if (indexOfCrumb === -1) {
      this.breadcrumbs.push(crumb);
    } else {
      this.breadcrumbs.length = indexOfCrumb + 1;
    }
  }

  spliceCrumbs(index: number) {
    if (!(this.breadcrumbs.length - 1 === index)) {
      this.breadcrumbs.splice(index);
      this.breadcrumbsBehaviorSubject.next(this.breadcrumbs);
    }
  }

  constructor() {}
}
