import { Component, OnInit, Input } from '@angular/core';
import {Result} from '../../Models/result.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.sass']
})
export class DataListComponent implements OnInit {
  @Input() result: Result;
  @Input() height: number;

  constructor() { }

  ngOnInit() {}

}
