import { of as observableOf } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { DetailView } from '../../Models/detailView.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Result } from '../../Models/result.model';
import { Program } from '../../Models/program.model';
import { AppComponent } from '../../app.component';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/observable/of';
import { ProgramAccessorService } from '../../Services/program-accessor.service';
import { OrganizationAccessorService } from '../../Services/organization-accessor.service';
import { CategoryAccessorService } from '../../Services/category-accessor.service';
import { SubcategoryAccessorService } from '../../Services/subcategory-accessor.service';
import { ThemeAccessorService } from '../../Services/theme-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';

@Component({
  selector: 'app-organization-view',
  templateUrl: './organization-view.component.html',
  styleUrls: [
    '../../../assets/styles/programAndOrganization-view.component.sass'
  ]
})
export class OrganizationViewComponent implements OnInit {
  id: string;
  detailView: DetailView;
  result: Result[] = [];
  lang: string;
  parent: AppComponent;
  programRequestEmail: string;

  constructor(
    private categoryAccessor: CategoryAccessorService,
    private organizationAccessor: OrganizationAccessorService,
    private preferencesService: PreferencesService,
    private programAccessor: ProgramAccessorService,
    private route: ActivatedRoute,
    private subcategoryAccessor: SubcategoryAccessorService,
    private themeAccessor: ThemeAccessorService
  ) {
  }

  ngOnInit() {
    this.lang = this.preferencesService.getLang();
    this.themeAccessor.getThemeElement('programRequestEmail').subscribe(element => {
      this.programRequestEmail = element;
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];

      this.organizationAccessor
        .getOrganizationById(this.id)
        .subscribe(organization => {
          this.programAccessor
            .getProgramsWhere(program => program.OrganizationId === this.id)
            .subscribe(programs => {
              this.programAccessor
                .getProgramsWhere(
                  program => program.OrganizationId === this.id,
                  this.lang
                )
                .subscribe(tPrograms => {
                  const tKeys = tPrograms.map(x => x.key);

                  const finalPrograms = programs.map(program => {
                    if (tKeys.includes(program.key)) {
                      return tPrograms.find(x => x.key === program.key);
                    }
                    return program;
                  });

                  // sorts the combined program list alphabetically
                  const associatedPrograms = this.sortProgramListObservable(
                    observableOf(finalPrograms)
                  );
                  const associatedProgramCategories = [];
                  const associatedProgramSubcategories = [];

                  associatedPrograms.subscribe(p => {
                    p.forEach(program => {
                      this.categoryAccessor
                        .getCategoryById(program.CategoryId, this.lang)
                        .subscribe(category => {
                          associatedProgramCategories[program.key] = category;
                        });
                      if (program.SubcategoryIds) {
                        const list = [];
                        program.SubcategoryIds.forEach(id => {
                          this.subcategoryAccessor
                            .getSubcategoryByIds(
                              program.CategoryId,
                              id,
                              this.lang
                            )
                            .subscribe(sub => {
                              list.push(sub);
                            });
                        });
                        associatedProgramSubcategories[program.key] = list;
                      } else if (program.SubcategoryId) {
                        this.subcategoryAccessor
                          .getSubcategoryByIds(
                            program.CategoryId,
                            program.SubcategoryId,
                            this.lang
                          )
                          .subscribe(sub => {
                            associatedProgramSubcategories[program.key] = [sub];
                          });
                      }
                    });
                  });

                  if (this.lang !== 'en') {
                    this.organizationAccessor
                      .getOrganizationById(this.id)
                      .subscribe(langOrg => {
                        const tempName = langOrg
                          ? langOrg.Name
                          : organization.Name;
                        this.detailView = {
                          id: this.id,
                          name: tempName,
                          phone: organization.Call,
                          website: organization.Website,
                          address: organization.Address,
                          description: null,
                          associatedPrograms: associatedPrograms,
                          associatedProgramCategories: associatedProgramCategories,
                          associatedProgramSubcategories: associatedProgramSubcategories
                        };
                      });
                  } else {
                    this.detailView = {
                      id: this.id,
                      name: organization.Name,
                      phone: organization.Call,
                      website: organization.Website,
                      address: organization.Address,
                      description: null,
                      associatedPrograms: associatedPrograms,
                      associatedProgramCategories: associatedProgramCategories,
                      associatedProgramSubcategories: associatedProgramSubcategories
                    };
                  }
                });
            });
        });
    });
  }

  sortProgramListObservable(list) {
    return list.map(data => {
      data.sort((a, b) => {
        const dna = this.displayName(a);
        const dnb = this.displayName(b);
        if (!dna) {
          return 1;
        }
        if (!dnb) {
          return -1;
        }
        return dna > dnb ? 1 : -1;
      });
      return data;
    });
  }

  displayName(program: Program) {
    return program.Summary
      ? program.Summary
      : program.Description
      ? program.Description.substring(0, 100)
      : '';
  }

  getEmailBody() {
    if (this.detailView) {
      // formats the body of the email.
      let body =
        `Please enter in any updates using the template below for EACH program that you would like to update. If the information is correct, please leave it blank.%0D%0A%0D%0A%0D%0A%0D%0A` +
        `ORGANIZATION NAME: ${this.detailView.name}%0D%0A%0D%0A` +
        `ID (LEAVE AS IS):${this.id}%0D%0A%0D%0A` +
        `ADDRESS: ${
          this.detailView.address ? this.detailView.address : 'none available'
        }%0D%0A%0D%0A` +
        `PHONE NUMBER: ${
          this.detailView.phone ? this.detailView.phone : 'none available'
        }%0D%0A%0D%0A` +
        `WEBSITE: ${
          this.detailView.website ? this.detailView.website : 'none available'
        }%0D%0A%0D%0A` +
        `NAME OF PROGRAM:%0D%0A%0D%0A` +
        `DESCRIPTION OF PROGRAM:%0D%0A%0D%0A%0D%0A%0D%0A` +
        `PROGRAM CLASSIFICATION AND PATH (FORMAT: CATEGORY > SUBCATEGORY > PROGRAM NAME):%0D%0A%0D%0A`;
      body = body.replace('&', '');
      return body;
    }
  }
}
