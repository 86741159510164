import { Injectable } from '@angular/core';
import {DataAccessorService} from './data-accessor.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {ObjectCache} from '../Models/objectCache.model';
import {Observable, of} from 'rxjs';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeAccessorService extends DataAccessorService<string> {

  themeElementMap = new Map();

  constructor(private db: AngularFireDatabase, private pf: PreferencesService) {
    super(db, `entities/${pf.getLocation()}/theme`, new ObjectCache<string>());
  }

  getThemeElement(elementName: string): Observable<string> {
    if (!this.themeElementMap.has(elementName)) {
      const elementObservable = super.getObjectById(elementName, '');
      elementObservable.subscribe(element => this.themeElementMap.set(elementName, of(element)));
      return elementObservable;
    }
    return this.themeElementMap.get(elementName);
  }

  async getSingleThemeElement(elementName: string): Promise<string> {
    const result = await this.getRawDataFromPath(elementName);
    return result;
  }

}
