<div>
  <h1 [ngStyle]="{'color': baseColor}">{{this.translationSet[0]}}</h1>
</div>

<app-breadcrumb></app-breadcrumb>
<div class="container">
    <div *ngFor="let subcategory of subcategories" class="column">
      <div class="row">
        <h2 [ngStyle]="{'color': baseColor}">{{subcategory.Name}}</h2>
        <div class="numbers" *ngFor="let program of hotlineProgramsAdjusted" [ngStyle]="{'color': baseColor}">
          <a *ngIf="program.subCategoryId === subcategory.key" [routerLink]="['/program', program.id]">
            <span class="names">{{program.name}}:</span> &nbsp; <span class="phone">{{program.info}}</span> </a>
        </div>
      </div>
    </div>
</div>
