import { Injectable } from '@angular/core';
import { ObjectCache } from '../Models/objectCache.model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { DBConnection } from '../Models/dbconnection.model';
import { database, analytics } from 'firebase';

export class DataAccessorService<T> {
  static dbRef: database.Database;
  static dbLoaded = false;

  static dbConnections: Observable<DBConnection[]>;
  static logoUrl: Observable<string>;

  PATH: string;
  cache: ObjectCache<T>;

  constructor(
    private angularFireDatabase: AngularFireDatabase,
    path: string,
    cache: ObjectCache<T>
  ) {
    this.PATH = path;
    this.cache = cache;
    this.loadDatabase();
  }

  async getRawDataFromPath(id?: string, path?: string): Promise<any> {
    let ref: database.Reference;
    const pathValue = path || this.PATH;

    if (id) {
      ref = DataAccessorService.dbRef.ref(`${pathValue}/` + id);
    } else {
      ref = DataAccessorService.dbRef.ref(`${pathValue}`);
    }

    return (await ref.once('value')).val();
  }

  loadDatabase() {
    if (!DataAccessorService.dbRef) {
        DataAccessorService.dbRef = this.angularFireDatabase.database.app.database();
        DataAccessorService.dbRef
          .ref('/')
          .once('value')
          .then(_ => (DataAccessorService.dbLoaded = true));
    }
  }

  protected getCachedObjects(lang?: string): T[] {
    this.getCachedDataEvent(lang || 'en');

    const fullLang = lang ? lang : 'en';
    if (this.cache[fullLang]) {
      return this.cache[fullLang];
    }

    const objectList = [];
    DataAccessorService.dbRef
      .ref(this.PATH)
      .once('value')
      .then(objectsSnapshot => {
        objectsSnapshot.forEach(objectSnapshot => {
          const object = objectSnapshot.val();
          object.key = objectSnapshot.key;
          objectList.push(object);
        });
      });

    this.cache[fullLang] = objectList;

    return objectList;
  }

  protected getObjectById(id: string, lang?: string): Observable<T> {
    this.getGlobalDataEvent(lang || 'en');

    const path = `${this.PATH}/${id}`;
    return this.angularFireDatabase
      .object<T>(DataAccessorService.dbRef.ref(path))
      .valueChanges();
  }

  protected getObjects(lang?: string): Observable<T[]> {
    this.getGlobalDataEvent(lang || 'en');

    return this.angularFireDatabase
      .list<T>(DataAccessorService.dbRef.ref(this.PATH))
      .snapshotChanges()
      .pipe(
        map(items => {
          return items.map(item => {
            const object = item.payload.val();
            // @ts-ignore
            object.key = item.key;
            return object;
          });
        })
      );
  }

  protected getObjectsWhere(filtr: Function, lang?: string): Observable<T[]> {
    this.getGlobalDataEvent(lang || 'en');

    return this.angularFireDatabase
      .list<T>(DataAccessorService.dbRef.ref(this.PATH))
      .snapshotChanges()
      .pipe(
        map(items => {
          return items
            .map(item => {
              const object = item.payload.val();
              // @ts-ignore
              object.key = item.key;
              return object;
            })
            .filter(item => filtr(item));
        })
      );
  }

  protected getList(path: string, lang?: string): Observable<T[]> {
    this.getGlobalDataEvent(lang || 'en');

    return this.angularFireDatabase
      .list<T>(DataAccessorService.dbRef.ref(path))
      .snapshotChanges()
      .pipe(
        map(items => {
          return items.map(item => {
            const object = item.payload.val();
            // @ts-ignore
            object.key = item.key;
            return object;
          });
        })
      );
  }

  protected getLang(lang) {
    return lang === 'en' || !lang ? '' : lang;
  }

  private getCachedDataEvent(language: string) {
    analytics().logEvent(
      'get_cached_data', {
        language
      }
    );
  }

  private getGlobalDataEvent(language: string) {
    analytics().logEvent(
      'get_global_data', {
        language
      }
    );
  }
}
