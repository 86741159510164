import { Injectable } from '@angular/core';
import {
  LocationCodeMapper,
  LocationLatLongMapper,
  LocationNameMapper,
} from '../Models/enums/location';
import { database, analytics } from 'firebase';
import { Language } from '../Models/language.model';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  public getLang(): string {
    const lang: string = localStorage.getItem('language') || '';
    return lang;
  }

  public async getLanguages(): Promise<{ string: Language }> {
    const ref = database().ref('languages');
    const result = (await ref.once('value')).val();
    return result;
  }

  public setLang(languageCode: string) {
    localStorage.setItem('language', languageCode);
    analytics().logEvent('set_language', {
      selectedLanguage: languageCode,
    });
    const route = this.router.url;
    if (route.includes('home')) {
      this.router.navigateByUrl('/home?language=' + languageCode); // without the code, a reload is not triggered.
    } else {
      window.location.href = '/categories'; // If you don't do it like this, the header is missing on the Categories page
    }
  }

  public setLocation(location: string) {
    localStorage.setItem('location', location);
    analytics().logEvent('set_location', {
      selectedLocation: LocationNameMapper[location],
    });
  }

  public getLocation(): string {
    const location: string = localStorage.getItem('location');
    return LocationCodeMapper[location];
  }

  public getLocationDisplayName(): string {
    const location: string = localStorage.getItem('location');
    return LocationNameMapper[location];
  }

  public getLocationLatLong(): any {
    const location: string = localStorage.getItem('location');
    return LocationLatLongMapper[location];
  }

  public setLangSilent(language: string) {
    localStorage.setItem('language', language);
  }

  public setLocationSilent(locationstring: string) {
    const locationId = Object.keys(LocationCodeMapper).find(
      (f) => LocationCodeMapper[f] === locationstring
    );
    if (locationstring !== this.getLocation() && locationId) {
      localStorage.setItem('location', locationId);
      location.reload();
    }
  }
}
