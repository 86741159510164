<div id="container">
  <h1 id="page_title" [ngStyle]="{'color': baseColor}">{{statics[10]}}</h1>
  <div id="inputContainer">
    <div id="address">
      <h3>{{statics[6]}}</h3>
      <mat-form-field id="addressInput">
        <input matInput #address placeholder="{{statics[8]}}" (keydown.enter)="addressEntered(address.value)">
      </mat-form-field>
    </div>
    <div class="category-filter">
      <mat-checkbox class="filter-margin" [(ngModel)]="food">{{statics[11]}}</mat-checkbox>
      <mat-checkbox class="filter-margin" [(ngModel)]="health">{{statics[12]}}</mat-checkbox>
      <mat-checkbox class="filter-margin" [(ngModel)]="other">{{statics[13]}}</mat-checkbox>
    </div>
  </div>
</div>

<div id="mapContainer">
  <agm-map (mapReady)="mapReady($event)">
    <agm-marker *ngIf="houseLocation" [latitude]="houseLocation.lat" [longitude]="houseLocation.lng" [iconUrl]="greenMarker">
      <agm-info-window>
        <strong>
        {{houseAddress}}
        </strong>
      </agm-info-window>
    </agm-marker>

    <agm-marker *ngFor="let marker of allMarkers"
                [latitude]="marker.location.lat"
                [longitude]="marker.location.lng"
                [visible]="marker.visible || ((marker.type == 'food' && food) || (marker.type == 'health' && health) || (marker.type == 'other' && other))"
                [iconUrl]="marker.type == 'food' ? orangeMarker : (marker.type == 'health' ? pinkMarker : blueMarker)">
      <agm-info-window>
        <strong>
          <a [routerLink]="['/program', marker.key]">
            {{marker.name}}
          </a>
        </strong>
        <br>
        {{marker.address}}
      </agm-info-window>
    </agm-marker>
  </agm-map>
</div>

