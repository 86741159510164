<div id="container">
  <div id="info-area">
    <app-detail-view [detailView]="detailView"></app-detail-view>
    <div style="margin-top: 50px">
      <a
        style="color: grey; text-decoration:underline;font-size:.6em;"
        href="mailto:{{ programRequestEmail }}?subject=Update Request&body={{
          getEmailBody()
        }}"
        >Report Incorrect or New Information</a
      >
    </div>
  </div>

  <div id="map-and-cal">
    <app-map-widget [address]="detailView?.address"></app-map-widget>
  </div>
</div>
