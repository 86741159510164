import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Program } from '../Models/program.model';
import { from, Observable } from 'rxjs';
import { DataAccessorService } from './data-accessor.service';
import { ObjectCache } from '../Models/objectCache.model';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramAccessorService extends DataAccessorService<Program> {
  constructor(private db: AngularFireDatabase, private pf: PreferencesService) {
    super(
      db,
      `entities/${pf.getLocation()}/Data/${pf.getLang()}/ProgramList`,
      new ObjectCache<Program>()
    );
  }

  getCachedPrograms(lang?: string): Program[] {
    return super.getCachedObjects(lang);
  }

  getProgramById(id: string, lang?: string): Observable<Program> {
    lang = lang || 'en';
    return from(
      super.getRawDataFromPath(
        id,
        `entities/${this.pf.getLocation()}/Data/${lang}/ProgramList`
      )
    );
  }

  getPrograms(lang?: string) {
    return super.getObjects(lang);
  }

  getProgramsWhere(filter: Function, lang?: string): Observable<Program[]> {
    return super.getObjectsWhere(filter, lang);
  }

  getProgramsBySubcategory(subcategoryId: string, categoryId: string, lang?: string): Observable<Program[]>{
    return this.getProgramsWhere(program =>
      (program.SubcategoryId === subcategoryId ||
      (program.SubcategoryIds !== undefined && program.SubcategoryIds.includes(subcategoryId))) &&
      program.CategoryId === categoryId, lang);
  }

  getProgramsByCategoryId(categoryId: string, lang?: string): Observable<Program[]>{
    return this.getProgramsWhere(program => program.CategoryId === categoryId, lang);
  }
}


