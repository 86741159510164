<div id="container">
  <h1 id="page_title" [ngStyle]="{ color: baseColor }">
    {{ translationSet[3] }}
  </h1>
  <h3 class="disclaimer">{{ translationSet[5] }}</h3>
  <div class="selectors">
    <button
      [disabled]="!back"
      [style.backgroundColor]="backColor"
      id="b1"
      (click)="viewBack()"
    >
      <
    </button>
    <h1 id="date">{{ dateString }}</h1>
    <button
      id="b2"
      (click)="viewForward()"
      [ngStyle]="{ 'background-color': baseColor }"
    >
      >
    </button>
    <mat-form-field id="calendar">
      <input
        matInput
        [min]="currentDate"
        [matDatepicker]="picker"
        placeholder="{{ translationSet[4] }}"
        (dateInput)="viewFuture($event.value)"
        (dateChange)="viewFuture($event.value)"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [startAt]="date"></mat-datepicker>
    </mat-form-field>
  </div>

  <ng-template #loading>
    <div class="text-center">
      <div class="loader"></div>
      <br />
      {{ translationSet[2] }}...
    </div>
  </ng-template>

  <div *ngIf="events | async; else loading; let events">
    <mat-card
      class="result"
      *ngFor="let event of events"
    >
    <a routerLink="/program/{{event.id}}">
      <div [ngClass]="event.icon">
        <i class="material-icons icons">{{ event.icon }}</i>
      </div>
      <div class="info">
        <h2>{{ event.name }}</h2>
        <h2>{{ event.time }}</h2>
        <p>{{ event.address }}</p>
      </div>
    </a>
    </mat-card>
  </div>
  <div *ngIf="!(events | async).length">
    {{translationSet[6]}}
  </div>
</div>
