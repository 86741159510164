<div id="screen" xmlns="http://www.w3.org/1999/html">
  <nav>
    <mat-toolbar [ngStyle]="{ 'background-color': baseColor }">
      <mat-toolbar-row>
        <span id="toolbar-spacer"></span>
        <mat-toolbar-row id="lang">
          <ul>
            <li
              *ngFor="let language of languages"
              (click)="setLang(language.code)"
            >
              {{ language.nativeName }}
            </li>
          </ul>
        </mat-toolbar-row>
      </mat-toolbar-row>
    </mat-toolbar>
  </nav>

  <div id="background" [ngStyle]="{ 'background-color': baseColor }"></div>
  <div class="”main-container”">
    <img id="main-logo" [src]="appLogo" alt="MyLink logo"/>
    <div id="city-select">
      <label for="location">{{ this.displayText?.landingPageTitle }}</label>
      <mat-form-field>
        <select matNativeControl required [(ngModel)]="selectedConnection" id="location">
          <ng-container *ngFor="let connection of entities">
            <option [value]="connection">{{
              locationNameMapper[connection]
            }}</option>
          </ng-container>
        </select>
      </mat-form-field>
      <button mat-raised-button (click)="redirectToWebsite()">GO</button>
    </div>
    <p id="description">{{ this.displayText?.landingPageSubtitle }}</p>
  </div>
</div>
