<div id="main">
  <div id="bar">
    <div>
      <i id="search" [ngStyle]="{ color: baseColor }" class="material-icons"
        >search</i
      >
      <input
        #searchBox
        id="searchBox"
        [(ngModel)]="searchBoxValue"
        (keydown)="handleInput($event)"
        (focus)="showSearchResults = true"
        (blur)="showSearchResults = false; selectedIndex = null"
        aria-label="Search"
      />
    </div>
    <p *ngIf="noResults">{{translationSet[0]}}</p>
  </div>
</div>
<div>
  <mat-list id="data-list" *ngIf="showSearchResults">
    {{ reset() }}
    <div *ngFor="let item of result">
      <mat-list-item
        [ngStyle]="{
          'background-color':
            tempCounter == selectedIndex ? 'lightgrey' : 'white'
        }"
        id="result-box"
        (mousedown)="showFilter = false; navigate(item)"
      >
        <a>
          <h1>{{ item.Name }}</h1>
          <h2>{{ item.Address }}</h2>
        </a>
        {{ inc() }}
      </mat-list-item>
    </div>
  </mat-list>
</div>
