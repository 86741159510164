export class Breadcrumb {
  constructor(n: string, r: string, icon?: string, fromCategories?:boolean) {
      this.Name = n;
      this.route = r;
      this.Icon = icon;
      this.fromCategories = fromCategories;
    }
  route: string;
  Name: string;
  Icon: string;
  fromCategories: boolean;
}
