
<h2>It is a mystery</h2>

<!--
<h1 style='color: #0E588A'>PDF Generation</h1>
<body>

<p>This page allows you to generate a PDF document with a list of Programs and Organizations that can be printed.<br/>Please be aware that not all devices may be able to display PDF documents correctly.<br/>Generation may take a moment.</p> 

    <button (click)="genPDF(selector.value, bodySize.value)">Click Here to generate PDF</button>  
    Text Size: <input class="choices" #bodySize type="number" value="16" size="3" maxlength="3">px              
    Paper Type: <select class="choices" #selector> <option value="letter">Letter (8.5x11)</option><option value="a4">A4 (8.27x11.69)</option><option value="legal">Legal (8.5x14)</option></select>

</body>
-->