<div class="privacy">
  <div class="content">
    <p><strong>MyLiNK, LLC</strong></p>
    <p><strong>Terms of Use</strong></p>
    <p><strong>Effective Date: October 10, 2020</strong></p>
    <p><strong>Introduction and Eligibility</strong></p>
    <p>These Terms of Use (&ldquo;<strong>Terms</strong>&rdquo;) apply to our MyLiNK platforms and any other related features, contents, applications, or websites that MyLiNK, LLC may offer from time to time (collectively, the &ldquo;<strong>Services</strong>&rdquo;).&nbsp; Please read these Terms carefully before using Services.</p>
    <p>These Terms include the MyLiNK, LLC Privacy Policy located at<strong>&nbsp;www.mylnk.app/privacy-policy</strong>, which is incorporated by reference into these Terms.&nbsp; We encourage you to review both documents carefully when using the Services.&nbsp;</p>
    <p><strong>These Terms are a legally binding contract.&nbsp; By creating an account or accessing or using the Services, you agree to the Privacy Policy and these Terms.&nbsp; If you do not agree with any terms or conditions of the Privacy Policy or these Terms, you must not create an account and must immediately cease your use of the Services.&nbsp; These Terms may change as our business changes, and you agree you will review it and any updates regularly.&nbsp; Your continued use of the Services means you accept any changes.</strong></p>
    <p><strong>Binding Agreement.&nbsp;</strong>These Terms constitute a binding agreement between you and MyLiNK, LLC (&ldquo;<strong>MyLiNK</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>us</strong>&rdquo;).&nbsp; &ldquo;<strong>You</strong>&rdquo; and &ldquo;<strong>users</strong>&rdquo; shall mean all visitors and users of the Services.&nbsp; You accept these Terms each time you access the Services.&nbsp; If you do not accept these Terms, you must not use the Services.&nbsp;</p>
    <p><strong>Eligibility.&nbsp;&nbsp;</strong>The Services are intended solely for persons who are 13 years of age or older.&nbsp; Any access to or use of the Services by anyone under age 13 is expressly prohibited.&nbsp; By accessing or using the Services, you represent and warrant that you are 13 years old or older.&nbsp; Further, if you are under 18 years of age, you represent and agree that you possess the legal consent of your parent or guardian to access and use the Services.</p>
    <p><strong>Revision to Terms.&nbsp;</strong>We may revise these Terms at any time by posting an updated version.&nbsp; You should visit this page periodically to review the most current Terms, because you are bound by them if you use the Services.&nbsp; Your continued use of the Services after a change to these Terms constitutes your binding acceptance of these Terms.</p>
    <p><strong>The Services</strong></p>
    <p>&ldquo;<strong>Personal Information</strong>,&rdquo; &ldquo;<strong>Financial Information</strong>,&rdquo; and &ldquo;<strong>Other Information,&rdquo;</strong>&nbsp;as used in these Terms, shall have the same meaning as defined in the MyLiNK, LLC Privacy Policy.</p>
    <p><strong>Permission to Use the Services.&nbsp;</strong>We grant you permission to use the Services subject to the restrictions in these Terms.&nbsp; Through the Services you can utilize tools and other features of the Services to find services and resources for people in crisis.&nbsp; Your use of the Services is at your own risk, including the risk that information and content that you store in your account is inaccurate or unreliable or that your &nbsp;account may be misused by someone with whom you provide access.</p>
    <p><strong>Your Account</strong></p>
    <p><strong>Account Registration.&nbsp;</strong>&nbsp;In order to use the Services, you must register to create a MyLiNK account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.&nbsp;</p>
    <p><strong>Your Login Credentials.</strong>&nbsp;&nbsp;To use the MyLiNK Service, you will log in with the user identification and a password of your choosing.&nbsp; Your login information and account is personal to you, and you may not share your account information with, or allow access to your account by, any third party.&nbsp; As you will be responsible for all activity that occurs under your access credentials, you agree to use reasonable efforts to prevent unauthorized access to or use of the Services and any device that you use to access the Services.&nbsp; You are responsible for safeguarding your password.&nbsp; If you forget your password, you will be asked to create a new one.&nbsp;</p>
    <p>You agree to notify us immediately of any breach in secrecy of your login credentials.&nbsp; If you have any reason to believe that your account information has been compromised or that your account has been accessed by a third party, you agree to immediately notify MyLiNK by email to&nbsp;<strong>support@mylnk.app</strong>.&nbsp; You will be solely responsible for the losses incurred by MyLiNK and others due to any unauthorized use of your account.</p>
    <p><strong>Communications</strong></p>
    <p><strong>Communications and Service Messages.&nbsp;</strong>You agree that we may communicate or provide notices to you in the following ways:</p>
    <ol>
    <li>By email to the email address you provide to create your account;</li>
    <li>Through banner notices, push notifications, in-app messages or other messages delivered on or through the Services; or</li>
    <li>Through other means including any telephone number, mobile number, or mailing address you choose to provide to us.</li>
    </ol>
    <p>You agree to keep any contact information you provide to us up to date.&nbsp; You may adjust your communications preferences in your MyLiNK account settings at any time or by unsubscribing from marketing emails using the link provided at the bottom of those emails.</p>
    <p><strong>Electronic Notices.&nbsp;</strong>&nbsp;By using the Services or providing Personal or Financial Information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services.&nbsp; If we learn of a security system&rsquo;s breach, we may attempt to notify you electronically by posting a notice on the Services or sending an email to you.&nbsp; You may have a legal right to receive this notice in writing.&nbsp; To receive free written notice of a security breach (or to withdraw your consent from receiving electronic notice), please write to us at&nbsp;<strong>support@mylnk.app</strong>.</p>
    <p><strong>Ownership and Use of Information and Content on the Services</strong></p>
    <p><strong>MyLiNK Content Ownership and Use.&nbsp;</strong>&nbsp;The contents of the Services include: designs, text, graphics, images, video, information, logos, button icons, software, computer code, and other content (collectively, &ldquo;<strong>MyLiNK Content</strong>&rdquo;).&nbsp; All MyLiNK Content and the compilation (meaning the collection, arrangement, and assembly) of all MyLiNK Content are the property of MyLiNK or its licensors and are protected under copyright, trademark, and other laws, and MyLiNK will retain all rights, title, and interests in and to the MyLiNK Content.</p>
    <p><strong>License Granted By You to MyLiNK.&nbsp;&nbsp;</strong>By making available any information on or through the Services, you grant and agree to grant to MyLiNK a perpetual, non-exclusive license to use such information (a) in order to provide the Services to you; (b) for statistical use (provided that such information is not personally identifiable); and (c) as necessary to monitor and improve the Services.</p>
    <p><strong>License Granted by MyLiNK to You.&nbsp;</strong>&nbsp;We authorize you, subject to these Terms, to access and use the Services and the MyLiNK Content solely for your personal and non-commercial purposes, at our discretion.&nbsp; Any other use is expressly prohibited.&nbsp; This license is revocable at any time without notice and with or without cause.&nbsp; Unauthorized use of the MyLiNK Content may violate copyright, trademark, and applicable communications regulations and statutes and is strictly prohibited.&nbsp; You must preserve all copyright, trademarks, service marks, and other proprietary notices contained in the original MyLiNK Content on any copy you make of the MyLiNK Content.</p>
    <p><strong>MyLiNK Marks.&nbsp;&nbsp;</strong>MyLiNK, the MyLiNK logo, and other MyLiNK logos and product and service names are or may be trademarks of MyLiNK (the &ldquo;<strong>MyLiNK Marks</strong>&rdquo;).&nbsp; Without our prior written permission, and except as solely enabled by any link as provided by us, you agree not to display or use in any manner the MyLiNK Marks.</p>
    <p><strong>Except as provided within these Terms, you may not copy, modify, create derivative works of, translate, publish, broadcast, transmit, distribute, perform, display, or sell any content appearing on or through the Services.</strong></p>
    <p><strong>Suggestions and Submissions</strong></p>
    <p>We appreciate and welcome your comments and feedback regarding the Services.&nbsp; Please be advised that if you send us creative ideas, suggestions, inventions, or materials (&ldquo;<strong>creative ideas</strong>&rdquo;), we shall:</p>
    <ol>
    <li>Own, exclusively, all now known or later discovered rights to the creative ideas;</li>
    <li>Not be subject to any obligation of confidentiality and shall not be liable for any use or disclosure of any creative ideas; and</li>
    <li>Be entitled to unrestricted use of the creative ideas for any purpose whatsoever, commercial or otherwise, without compensation to you or any other person.</li>
    </ol>
    <p><strong>Disclaimers, Limitations, and Prohibitions</strong></p>
    <p>We do not represent or guarantee the truthfulness, accuracy, or reliability of any content or information input into the Services by you or any other third party.&nbsp; You are solely responsible for any and all fees or costs you may incur to access the Services through any wireless or other communication service.</p>
    <p>You agree to use the Services only for their intended purposes.&nbsp; You must use the Services in compliance with all privacy, data protection, intellectual property, and other applicable laws.&nbsp; The following uses of the Services are prohibited.&nbsp; You may not:</p>
    <ol>
    <li>Attempt to interfere with, harm, reverse engineer, steal from, or gain unauthorized access to the Services, user accounts, or the technology and equipment supporting the Services;</li>
    <li>Frame or link to the Services without permission;</li>
    <li>Use data mining, robots, or other data gathering devices on or through the Services;</li>
    <li>Impersonate another person, or misrepresent your affiliation with a person or entity;</li>
    <li>Disclose Personal or Financial Information about another person without their consent;</li>
    <li>Sell, transfer, or assign any of your rights to use the Services to a third party without our express written consent;</li>
    <li>Use the Services or MyLiNK Content for any commercial purpose or in any manner not permitted by these Terms;</li>
    <li>Use the Services in an illegal way or to commit an illegal act in relation to the Services or that otherwise results in fines, penalties, and other liability to MyLiNK or others;</li>
    <li>Access the Services from a jurisdiction where it is illegal or unauthorized; or</li>
    <li>Encourage or enable any other individual to do any of the above.</li>
    </ol>
    <p><strong>Account Termination, Removal of Records, and Consequences of Violating These Terms</strong></p>
    <p><strong>Account Termination.&nbsp;</strong>We reserve the right to suspend or terminate your account and prevent access to the Services for any reason, at our discretion. We reserve the right to refuse to provide the Services to you in the future.</p>
    <p><strong>Your Liability for Breach of these Terms.&nbsp;</strong>You are responsible for any claims, fees, fines, penalties, and other liability incurred by us or others caused by, arising out of, or related to your breach of these Terms and your use of the Services.</p>
    <p><strong>MyLiNK&rsquo;s Liability</strong></p>
    <p><strong>Changes to the Services</strong>.&nbsp;We may change, suspend, or discontinue any aspect of the Services at any time, including hours of operation or availability of the Services or any feature, without notice or liability.</p>
    <p><strong>Content Accuracy</strong>.&nbsp;We make no representations about accuracy, reliability, completeness, or timeliness of any content of the Services. Similarly, we make no representations about accuracy, reliability, completeness, or timeliness of any data from a third-party service provider or the quality or nature of third-party products or services obtained through the Services. Use the Services at your own risk.</p>
    <p><strong>Third-Party Websites</strong>.&nbsp;The Services may include links to third party websites and applications. You are responsible for evaluating whether you want to access or use them. We are not responsible for and do not endorse any features, content, advertising, products, or other materials on other websites or applications. You assume all risk and we disclaim all liability arising from your use of them.</p>
    <p><strong>Released Parties Defined</strong>.&nbsp;&ldquo;<strong>Released Parties</strong>&rdquo; include MyLiNK, LLC, and its affiliates, officers, employees, agents, partners, and licensors.</p>
    <p>We make no promises and disclaim all liability of specific results from the use of the Services.</p>
    <p><strong>DISCLAIMER OF WARRANTIES</strong></p>
    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (A) YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK, AND THE SERVICES ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS AND THE RELEASED PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO PRODUCTS OR SERVICES OFFERED BY BUSINESSES LISTED ON THE SERVICES, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B) THE RELEASED PARTIES MAKE NO WARRANTY THAT (i) THE SERVICES WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY GOODS OR SERVICE AVAILABLE ON THE SERVICES WILL MEET YOUR EXPECTATIONS, AND (iv) ANY ERRORS IN THE SERVICES WILL BE CORRECTED; AND (C) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL.</p>
    <p><strong>LIMITATION OF LIABILITY AND INDEMNIFICATION</strong></p>
    <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASED PARTIES SHALL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF MYLINK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM OR RELATED TO ANY OF THE FOLLOWING: (I) THE USE OR THE INABILITY TO USE THE SERVICES; (II) THE COST OF PROCUREMENT OF A SUBSTITUTE SERVICE RESULTING FROM ANY DATA, INFORMATION OR SERVICES OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH, FROM, OR AS A RESULT OF THE SERVICES; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY USER OR THIRD PARTY ON OR THROUGH THE SERVICES; (V) YOUR RELIANCE ON CONTENT MADE AVAILABLE BY US; OR (VI) ANY OTHER MATTER RELATING TO THE SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH MAY NOT APPLY TO YOU.</p>
    <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE RELEASED PARTIES&rsquo; MAXIMUM AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR YOUR USE OF MYLINK CONTENT, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE), WILL NOT EXCEED THE AMOUNT ACTUALLY PAID BY YOU TO MYLINK FOR USE OF THE SERVICES IN THE SIX (6) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR LIABILITY.</p>
    <p>You agree to defend, indemnify, and hold harmless the Released Parties from and against any claims, actions, or demands, including without limitation reasonable legal and accounting fees, alleging, arising out of, or related to (i) your use of or reliance on any third-party content, (ii) your use of or reliance on any MyLiNK Content, or (iii) your breach of these Terms. We shall provide notice to you promptly of any such claim, suit, or proceeding.</p>
    <p><strong>General Terms</strong></p>
    <p>These Terms, together with Privacy Policy, constitute the entire agreement between you and MyLiNK concerning your use of the Services. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties&rsquo; intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect. The section titles and annotations in these Terms are for convenience only and have no legal or contractual effect. The English-language versions of the Terms and incorporated agreements, such as the Privacy Policy, contain the binding terms, and any translations into other languages are for convenience only. If the English-language version conflicts with any translation, the English-language version controls.</p>
    <p><strong>Arbitration, Class Waiver, and Waiver of Jury Trial</strong></p>
    <p>These Terms and the relationship between you and MyLiNK shall be governed by the laws of the state of Nebraska without regard to its conflict of law provisions. You agree to first contact MyLiNK at&nbsp;<strong>support@mylnk.app&nbsp;</strong>regarding any claim or controversy arising out of or relating to these Terms or your use of the Services. Any controversy or claim arising out of or relating to these Terms, or the breach thereof, shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Any such arbitration, to the extent necessary, shall be conducted in in the state of Nebraska. You covenant not to sue MyLiNK in any other forum.</p>
    <p>You also acknowledge and understand that, with respect to any dispute with the Released Parties arising out of or relating to your use of the Services or these Terms:</p>
    <ul>
    <li>YOU HEREBY IRREVOCABLY WAIVE YOUR RIGHT TO HAVE A TRIAL BY JURY INVOLVING ANY SUCH DISPUTE;</li>
    <li>YOU HEREBY IRREVOCABLY WAIVE YOUR RIGHT TO SERVE AS A REPRESENTATIVE, AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN ANY LAWSUIT OR ARBITRATION PROCEEDINGS INVOLVING ANY SUCH DISPUTE; AND</li>
    <li>YOU MUST FILE ANY CLAIM WITHIN ONE (1) YEAR AFTER SUCH CLAIM AROSE OR IT IS FOREVER BARRED.</li>
    </ul>
    <p><strong>Contact Information</strong></p>
    <p>MyLiNK, LLC</p>
    <p>151 N 8<sup>th</sup> Suite 300</p>
    <p>Lincoln, NE 68508</p>
    <p><a href="mailto:support@mylnk.app">support@mylnk.app</a></p>
    <p>&nbsp;</p>
  </div>
</div>
