import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  NgModule
} from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ScheduleResult } from '../../Models/scheduleResult.model';
import { Program } from '../../Models/program.model';
import { SubcategoryAccessorService } from '../../Services/subcategory-accessor.service';
import { ProgramAccessorService } from '../../Services/program-accessor.service';
import { OrganizationAccessorService } from '../../Services/organization-accessor.service';
import { ScheduleAccessorService } from '../../Services/schedule-accessor.service';
import { ThemeAccessorService } from '../../Services/theme-accessor.service';
import * as moment from 'moment';
import { PreferencesService } from 'src/app/Services/preferences.service';

@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.sass']
})
export class CalendarComponent implements OnInit {
  currentDate: Date = new Date();
  date: Date = new Date();
  dateString: string;
  events = new Observable<ScheduleResult[]>();
  days: String[] = [
    `Sunday`,
    `Monday`,
    `Tuesday`,
    `Wednesday`,
    `Thursday`,
    `Friday`,
    `Saturday`
  ];
  monthsEnglish: String[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  monthsSpanish: String[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ];
  monthsArabic: String[] = [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سمتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسيمبر'
  ];
  monthsUkrainian: String[] = [
    'Січень',
    'Лютий',
    'Березень',
    'Квітень',
    'Травень',
    'Червень',
    'Липень',
    'Серпень',
    'Вересень',
    'Жовтень',
    'Листопад',
    'Грудень'
  ];
  monthsDari: String[] = [
    'ژانویه',
    'ماه فبروری',
    'مارس',
    'آوریل',
    'ماه مه',
    'جون',
    'جولای',
    'آگست',
    'سپتامبر',
    'اکتبر',
    'نوامبر',
    'دسامبر'
  ];
  monthsPashtu: String[] = [
    'جنوري',
    'فبروري',
    'مارچ',
    'اپریل',
    'می',
    'جون',
    'جولای',
    'اګست',
    'سپتمبر',
    'اکتوبر',
    'نومبر',
    'دسمبر'
  ];
  monthsKurdish: String[] = [
    'مانگى كانوونى دووهەم',
    'شوبات',
    'ئادار',
    'نیسان',
    'ڕەنگە',
    'حوزەیران',
    'تەموز',
    'ئاب',
    'ئەیلول',
    'تشرینی یەکەم',
    'تشرینی دووەم',
    'کانونی یەکەم'
  ];
  monthsKurmanji: String[] = [
    'Rêbendan',
    'Reşemî',
    'Adar',
    'Avrêl',
    'Gulan',
    'Pûşper',
    'Tîrmeh',
    'Tebax',
    'Îlon',
    'Cotmeh',
    'Mijdar',
    'Berfanbar'
  ];
  back = false;
  backColor = 'grey';
  baseColor: string;
  translatedTags = [
    [
      'Back',
      'Forward',
      'Loading',
      'Food and Health calendar',
      'Choose a Date',
      'Please see Coronavirus (COVID 19) Category for changes to food distribution as many services have changed. Some service have specific requirements. Click each entry for further details.',
      'No scheduled programs found. Please choose another date'
    ],
    [
      'Trước',
      'Kế tiếp',
      'Tải',
      'Lịch Thực phẩm &amp; Sức khỏe',
      'Chọn một ngày',
      'Vui lòng xem Danh mục coronavirus (COVID 19) để biết các thay đổi trong phân phối thực phẩm vì nhiều dịch vụ đã thay đổi. Một số dịch vụ có yêu cầu cụ thể. Nhấp vào từng mục để biết thêm chi tiết.',
      'Không tìm thấy chương trình theo lịch trình. Vui lòng chọn ngày khác'
    ],
    [
      'Anterior',
      'Siguiente',
      'Cargando',
      'Calendario de alimentos y salud',
      'Elige una fecha',
      'Consulte la categoría Coronavirus (COVID 19) para ver los cambios en la distribución de alimentos, ya que muchos servicios han cambiado. Algunos servicios tienen requisitos específicos. Haga clic en cada entrada para más detalles.',
      'No se encontraron programas programados. Por favor, elija otra fecha'
    ],
    [
      'التالى',
      'سابق',
      'جارى التحميل',
      'التقويم الغذائي والصحي',
      'اختيار التاريخ',
      'يرجى الاطلاع على فئة Coronavirus (COVID 19) للتغييرات التي تطرأ على توزيع الأغذية حيث تغيرت العديد من الخدمات. بعض الخدمات لها متطلبات محددة. انقر فوق كل إدخال لمزيد من التفاصيل.',
      'لم يتم العثور على برامج مجدولة. الرجاء اختيار تاريخ آخر'
    ],
    [
      'Gadaal',
      'Horay u soco',
      'Raadinta',
      'Kalandarka cuntada iyo caafimaadka',
      'Xulo Taariikh',
      'Fadlan eeg Qeybta Coronavirus (COVID 19) Qeybta wax ka beddelka qeybinta cuntada maaddaama adeegyo badani is beddeleen. Adeegyada qaarkood waxay leeyihiin shuruudo gaar ah. Guji gal kasta wixii faahfaahin dheeraad ah.',
      'Lama helin barnaamijyo la qorsheeyay. Fadlan dooro taariikh kale'
    ],
    [
      'Hазад',
      'Bперед',
      'Завантаження',
      `Календар харчування та здоров'я`,
      'Виберіть дату',
      'Будь ласка, перегляньте категорію Коронавірус (COVID 19), щоб дізнатися про зміни в роздачі їжі, оскільки змінилося багато послуг. Деякі служби мають особливі вимоги. Щоб отримати додаткові відомості, натисніть кожен запис.',
      'Запланованих програм не знайдено. Будь ласка, оберіть іншу дату'
    ],
    [
      'بازگشت',
      'به جلو',
      'بارگیری',
      'تقویم غذا و بهداشت',
      'تاریخ را انتخاب کنید',
      'لطفاً به کروناویروس (COVID 19) رده برای تغییرات در توزیع مواد غذایی به عنوان بسیاری از خدمات تغییر کرده است. برخی از خدمات دارای الزامات خاص. برای جزئیات بیشتر روی هر ورودی کلیک کنید.',
      'هیچ برنامه برنامه ریزی شده یافت نشد. لطفا تاریخ دیگری را انتخاب کنید'
    ],
    [
      'شاته',
      'مخکی',
      'بار کول',
      'د خوړو او روغتیا تقویم',
      'یوه نیټه وټاکئ',
      'مهرباني وکړئ د خواړو توزیع کې د بدلونونو لپاره د کورونویرس (COVID 19) کټګورۍ وګورئ ځکه چې ډیری خدمات بدل شوي. ځینې ​​خدمتونه ځانګړي اړتیاوې لري. د نورو جزیاتو لپاره هر ننوتل کلیک وکړئ.',
      'هیڅ پلان شوی پروګرامونه ونه موندل شول. لورینه وکړئ یوه بله نېټه وټاکئ'
    ],
    [
      'گەڕانەوە',
      'بۆ پێشەوە',
      'بارکردن',
      'ساڵنامەی خۆراک و تەندروستی',
      'بەروارێک هەڵبژێرە',
      'تکایە سەیری پۆلی کۆرۆنا (COVID 19) بکە بۆ زانینی گۆڕانکاری لە دابەشکردنی خۆراکدا بەو پێیەی زۆرێک لە خزمەتگوزارییەکان گۆڕانکارییان بەسەردا هاتووە. هەندێک خزمەتگوزاری پێداویستی تایبەتیان هەیە. بۆ زانیاری زیاتر کلیک لە هەر نووسینێک بکە.',
      'هیچ بەرنامەیەکی بەرنامەکراو نەدۆزرایەوە. تکایە بەروارێکی تر هەڵبژێرە'
    ],
    [
      'Paş',
      'Pêşve',
      'Barkirin',
      'Salnameya Xwarin û Tenduristiyê',
      'Dîrokek hilbijêrin',
      'Ji kerema xwe ji bo guhertinên di belavkirina xwarinê de Kategoriya Coronavirus (COVID 19) bibînin ji ber ku gelek karûbar hatine guhertin. Hin karûbar pêdiviyên taybetî hene. Ji bo hûrguliyên bêtir li her navnîşê bikirtînin.',
      'Bernameyên plankirî nehatin dîtin. Ji kerema xwe dîrokeke din hilbijêrin'
    ]
  ];
  translationSet = [];
  private foodCategoryId: string;

  constructor(
    private organizationAccessor: OrganizationAccessorService,
    private preferencesService: PreferencesService,
    private programAccessor: ProgramAccessorService,
    private router: Router,
    private scheduleAccessor: ScheduleAccessorService,
    private themeAccessor: ThemeAccessorService
  ) {
  }

  ngOnInit() {
    switch (this.preferencesService.getLang()) {
      case 'vi':
        this.translationSet = this.translatedTags[1];
        break;
      case 'es':
        this.translationSet = this.translatedTags[2];
        break;
      case 'ar':
        this.translationSet = this.translatedTags[3];
        break;
      case 'so':
        this.translationSet = this.translatedTags[4];
        break;
      case 'uk':
        this.translationSet = this.translatedTags[5];
        break;
      case 'prs':
        this.translationSet = this.translatedTags[6];
        break;
      case 'ps':
        this.translationSet = this.translatedTags[7];
        break;
      case 'ku':
        this.translationSet = this.translatedTags[8];
        break;
      case 'kmr':
        this.translationSet = this.translatedTags[9];
        break;
      default:
        this.translationSet = this.translatedTags[0];
    }

    this.themeAccessor.getThemeElement('baseColor').subscribe(element => {
      this.baseColor = element;
    });

    this.themeAccessor.getThemeElement('foodCategoryId').subscribe((id) => {
      this.foodCategoryId = id;
    });

    this.updateView();
  }

  viewBack(): void {
    // Makes sure you dont go back before current date.
    if (
      !(
        this.date.getDate() === this.currentDate.getDate() &&
        this.date.getMonth() === this.currentDate.getMonth()
      )
    ) {
      this.date.setDate(this.date.getDate() - 1);
      this.updateView();
    }
  }

  viewForward(): void {
    this.date.setDate(this.date.getDate() + 1);
    this.updateView();
  }

  viewFuture(event: any) {
    this.date.setTime(Date.parse(event));
    this.updateView();
  }

  updateView(): void {
    if (
      this.date.getDate() === this.currentDate.getDate() &&
      this.date.getMonth() === this.currentDate.getMonth()
    ) {
      this.back = false;
      this.backColor = 'grey';
    } else {
      this.back = true;
      this.backColor = this.baseColor;
    }

    switch (this.preferencesService.getLang()) {
      case 'es':
        this.dateString =
          this.date.getDate() +
          ' de ' +
          this.monthsSpanish[this.date.getMonth()];
        break;
      case 'vi':
        this.dateString =
          'ngày ' +
          this.date.getDate() +
          ' tháng ' +
          (this.date.getMonth() + 1);
        break;
      case 'ar':
        this.dateString =
          this.monthsArabic[this.date.getMonth()] + ' ' + this.date.getDate();
        break;
      case 'uk':
        this.dateString =
          this.date.getDate() +
          ' з ' +
          this.monthsUkrainian[this.date.getMonth()];
        break;
      case 'prs':
        this.dateString =
          this.monthsDari[this.date.getMonth()] + ' ' + this.date.getDate();
        break;
      case 'ps':
        this.dateString =
          this.monthsPashtu[this.date.getMonth()] + ' ' + this.date.getDate();
        break;
      case 'ku':
        this.dateString =
          this.monthsKurdish[this.date.getMonth()] + ' ' + this.date.getDate();
        break;
      case 'kmr':
        this.dateString =
          this.date.getDate() +
          ' ji ' +
          this.monthsKurmanji[this.date.getMonth()];
        break;
      default:
        this.dateString =
          this.monthsEnglish[this.date.getMonth()] + ' ' + this.date.getDate();
        break;
    }

    this.events = this.getEventsBySchedule(this.date);
  }

  getEventsBySchedule(currentDate: Date): Observable<ScheduleResult[]> {
    // reassigning the scope of the data service to be usable inside the subscribers
    // get number of current week (as in the first week of month would = 1)
    const numOfWeek = Math.ceil(currentDate.getDate() / 7);
    // creates observable of events
    const events = Observable.create(observer => {
      // insantiates temporary holder array that will later be converted to observable
      const eventsArray: ScheduleResult[] = [];
      this.programAccessor
        .getPrograms(this.preferencesService.getLang())
        .subscribe(transPrograms => {
          this.programAccessor.getPrograms().subscribe(programs => {
            // grabs list of schedules
            this.scheduleAccessor.getSchedules().subscribe(schedulesList => {
              // iterates thru all schedules
              schedulesList.forEach(s => {
                // boolean that keeps track of whether current day contains the schedule
                let contains = false;
                let checkWeek;
                // true if event occurs every day
                if (s.Cycle === 'Daily') {
                  contains = true;
                } else if (s.Cycle === 'Weekly') {
                  // true if once a week on current day of week
                  if (s.Day === this.days[currentDate.getDay()]) {
                    contains = true;
                  }
                } else if (s.Cycle === 'Monthly') {
                  // splits if includes multiple words
                  if (s.Day.includes(' ')) {
                    const split = s.Day.split(' ');
                    const week = split[0];
                    const monthlyDay = split[1];
                    switch (week) {
                      case 'First': {
                        checkWeek = 1;
                        break;
                      }
                      case 'Second': {
                        checkWeek = 2;
                        break;
                      }
                      case 'Third': {
                        checkWeek = 3;
                        break;
                      }
                      case 'Fourth': {
                        checkWeek = 4;
                        break;
                      }
                      case 'Fifth': {
                        checkWeek = 5;
                        break;
                      }
                      default: {
                        console.log('Invalid choice');
                        break;
                      }
                    }
                    // checks to see if week of month is current week & day of week is current day
                    if (
                      checkWeek === numOfWeek &&
                      monthlyDay === this.days[currentDate.getDay()]
                    ) {
                      contains = true;
                    }
                  }
                } else {
                  console.log('error! ' + s.Cycle);
                }
                if (contains) {
                  let relatedProgram: Program;

                  relatedProgram = programs.find(p => p.key === s.Id);

                  if (transPrograms) {
                    relatedProgram = transPrograms.find(p => p.key === s.Id);
                  }

                  if (relatedProgram) {
                    let displayTime = '';
                    if (s.EndTime) {
                      displayTime =
                        moment(s.StartTime, 'HH:mm').format('h:mma') +
                        ' to ' +
                        moment(s.EndTime, 'HH:mm').format('h:mma');
                    } else {
                      displayTime = moment(s.StartTime, 'HH:mma').format(
                        'h:mma'
                      );
                    }
                    eventsArray.push({
                      id: s.Id,
                      name: Program.getDisplayName(
                        this.organizationAccessor,
                        relatedProgram,
                        100,
                        this.preferencesService.getLang()
                      ).trim(),
                      address: `${relatedProgram.Address}`,
                      time: displayTime,
                      StartTime: `${s.StartTime}`,
                      EndTime: `${s.EndTime}`,
                      icon:
                        relatedProgram.CategoryId === this.foodCategoryId
                          ? 'restaurant'
                          : 'local_pharmacy'
                    });
                  }
                }
              });
              eventsArray.sort(this.SortByDateAndHealth);
              // converts array to observable to be returned
              observer.next(eventsArray);
            });
          });
        });
    });
    return events;
  }

  SortByDateAndHealth(a: ScheduleResult, b: ScheduleResult): number {
    if (a.icon === 'local_pharmacy' && b.icon !== 'local_pharmacy') {
      return -1;
    }
    if (a.icon !== 'local_pharmacy' && b.icon === 'local_pharmacy') {
      return 1;
    }

    if (moment(a.StartTime, 'HH:mm') > moment(b.StartTime, 'HH:mm')) {
      return 1;
    }
    if (moment(a.StartTime, 'HH:mm') < moment(b.StartTime, 'HH:mm')) {
      return -1;
    }
  }
}
