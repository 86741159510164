// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCNQC6CgYw2MKSFXkZqeH4MiF4mSCJZrZ4',
    authDomain: 'mylnk-qa-1234.firebaseapp.com',
    databaseURL: 'https://mylink-qa.firebaseio.com',
    projectId: 'mylnk-qa-1234',
    storageBucket: 'mylnk-qa-1234.appspot.com',
    messagingSenderId: '1018649692112',
    appId: '1:1018649692112:web:9e8ac022cff4536d911bd5',
    measurementId: 'G-ZY1YFZZ879',
  },
  googleMapsApiKey: 'AIzaSyB02uJWNaTpxDIk2ktNryo3zpfhrs8odXE',
  functionsUrl: 'https://us-central1-mylnk-qa-1234.cloudfunctions.net',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
