import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Program } from '../../Models/program.model';
import { Organization } from '../../Models/organization.model';
import { Router, NavigationStart } from '@angular/router';
import { ProgramAccessorService } from '../../Services/program-accessor.service';
import { OrganizationAccessorService } from '../../Services/organization-accessor.service';
import { ThemeAccessorService } from '../../Services/theme-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import Fuse from 'fuse.js';
import * as tokenizer from 'wink-tokenizer';
import { SearchData, SearchResult } from 'src/app/Models/search-result';
import { combineLatest } from 'rxjs';
import { analytics, database } from 'firebase/app';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.sass']
})
export class SearchBarComponent implements OnInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  translatedTags = {
    ar: ['لا توجد نتائج'],
    en: ['No Results'],
    es: ['Sin resultados'],
    vi: ['Không có kết quả'],
    kmr: ['Encam nade'],
    ku: ['هیچ ئەنجامێک'],
    prs: ['بدون نتیجه'],
    ps: ['هېڅ پایلې '],
    so: ['Natiijada kama soo bixin'],
    uk: ['Немає результатів'],
  };
  translationSet = [];
  searchBoxValue = '';
  searchPrograms = true;
  searchOrganizations = true;
  showFilter = false;
  result: SearchResult[] = [];
  programs: Program[] = [];
  organizations: Organization[] = [];
  timeout = null;
  searchWait = 300;
  showSearchResults = false;
  selectedIndex = null;
  noResults = false;

  tempCounter = 0;
  totalResults = 0;

  baseColor: string;
  searchData: SearchData[] = [];

  constructor(
    private organizationAccessor: OrganizationAccessorService,
    private preferencesService: PreferencesService,
    private programAccessor: ProgramAccessorService,
    private router: Router,
    private themeAccessor: ThemeAccessorService
  ) {}

  public async ngOnInit(): Promise<void> {
    const org = this.organizationAccessor.getOrganizations();
    const prog = this.programAccessor.getPrograms();
    combineLatest([org, prog]).subscribe(([orgVal, progVal]) => {
      if (orgVal) {
        this.organizations = orgVal;
      }
      if (progVal) {
        this.programs = progVal;
      }
      if (progVal || orgVal) {
        this.setupSearchData();
      }
    });
    this.translationSet = this.translatedTags[this.preferencesService.getLang()];

    this.themeAccessor.getThemeElement('baseColor').subscribe(element => {
      this.baseColor = element;
    });

    // Some other pages are using this cached data so keeping this for now.
    this.organizationAccessor.getCachedOrganizations();
    this.programAccessor.getCachedPrograms();

    // resets search box on renativating pages, since the actual component persists.
    this.router.events.forEach(event => {
      if (event instanceof NavigationStart) {
        this.searchBoxValue = '';
        this.result = [];
      }
    });
  }

  reset() {
    this.tempCounter = 0;
  }

  inc() {
    this.tempCounter++;
  }

  dec() {
    this.tempCounter--;
  }

  handleInput(event) {
    switch (event.code) {
      case 'ArrowUp':
        event.preventDefault();
        this.handleArrow('up');
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.handleArrow('down');
        break;
      case 'Enter':
        event.preventDefault();
        this.goToSelected();
        break;
      default:
        this.search();
        break;
    }
  }

  goToSelected() {
    let t = 0;
    if (this.selectedIndex === 0) {
      this.navigate(this.result[0]);
      return;
    }
    this.result.forEach(result => {
      if (t++ === this.selectedIndex) {
        this.navigate(result);
        return;
      }
    });
  }

  handleArrow(code) {
    if (this.result.length === 0) {
      return;
    }
    if (this.selectedIndex == null) {
      if (code === 'up') {
        this.selectedIndex = this.totalResults - 1;
      } else {
        this.selectedIndex = 0;
      }
    } else {
      if (code === 'up') {
        this.selectedIndex = this.selectedIndex - 1;
        if (this.selectedIndex === -1) {
          this.selectedIndex = this.totalResults - 1;
        }
      } else {
        this.selectedIndex = (this.selectedIndex + 1) % this.totalResults;
      }
    }

    const children = document.getElementById('data-list').children;

    let t = 0;
    for (let i = 0; i < children.length; i++) {
      if (t++ === this.selectedIndex) {
        this.scrollTo(children[i]);
        return;
      }
      for (let j = 1; j < children[i].children.length; j++) {
        if (t++ === this.selectedIndex && children[i][j]) {
          this.scrollTo(children[i][j]);
          return;
        }
      }
    }
  }

  scrollTo(element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  search() {
    this.noResults = false;
    this.totalResults = 0;
    this.selectedIndex = null;
    clearTimeout(this.timeout);
    // creates a timeout buffer to properly load results before trying to show them.
    // this could also probable be changed to be done more efficiently with a callback
    this.timeout = setTimeout(() => {
      this.result = [];
      if (this.searchBoxValue !== '') {
        this.performSearch();
        this.showSearchResults = true;
      } else {
        // clears results on a blank query
        this.result = [];
      }
    }, this.searchWait);
  }

  grabSearchboxFocus() {
    this.searchBox.nativeElement.focus();
  }

  navigate(result: SearchResult) {
    this.router.navigate(['/' + (result.IsProgram ? 'program' : 'organization'), result.Id]);
  }

  private createTags(text: string): string[] {
    const myTokenizer = tokenizer();
    const token = myTokenizer.tokenize(text.toLowerCase());
    const tokenArray: string[] = Array.from(token.filter(x => x.tag === 'word').map(y => y.value));
    const unique = tokenArray.filter((v, i, a) => a.indexOf(v) === i);
    return unique;
  }

  private performSearch(): void {
    const fuse = new Fuse(this.searchData, {
      includeScore: true,
      isCaseSensitive: false,
      shouldSort: true,
      useExtendedSearch: true,
      keys: [
        {
          name: 'SummaryTags',
          weight: 1
        },
        {
          name: 'OrgTags',
          weight: 1
        },
        {
          name: 'DescriptionTags',
          weight: 0.03
        },
        {
          name: 'Phone',
          weight: 0.01
        },
        {
          name: 'Address',
          weight: 0.01
        }
      ]
    });

    const searchText = this.searchBoxValue.toLowerCase().split(' ').map(text => '=' + text).join(' | ');
    let result = fuse.search(searchText);
    // get organizations without programs
    const organizationIds = result.filter(x => !x.item.IsProgram).map(y => y.item.OrganizationId); // only organizations
    const programOrgIds = result.filter(x => x.item.IsProgram).map(y => y.item.OrganizationId);
    const intersection = organizationIds.filter(x => programOrgIds.includes(x)); // find programs that exist in both

    // Show first 30 items
    result = result
      .filter(x => x.item.IsProgram || (!x.item.IsProgram && !intersection.includes(x.item.OrganizationId)))
      .slice(0, 30)
      .sort((a, b) => a.score - b.score || a.item.DisplayName.localeCompare(b.item.DisplayName));
    this.result = result.map(x => {
      const searchResultItem: SearchResult = {
        Name: x.item.DisplayName,
        Address: x.item.Address,
        Id: x.item.ProgramId ? x.item.ProgramId : x.item.OrganizationId,
        IsProgram: x.item.IsProgram
      };
      return searchResultItem;
    });

    this.noResults = this.result.length === 0;

    this.totalResults = this.result.length;
    analytics().logEvent('search_performed', {
      searchText: this.searchBoxValue,
      resultCount: this.totalResults
    });
    const ref = database().ref('searches');
  }

  private setupSearchData() {
    this.searchData = [];

    this.organizations.forEach(org => {
      if (org && org.Name) {
        const searchItem: SearchData = {
          OrganizationId: org.key,
          IsProgram: false,
          DisplayName: org.Name.trim(),
          Address: org.Address
        };
        searchItem.OrgTags = this.createTags(org.Name.trim());
        this.searchData.push(searchItem);
      }
    });

    this.programs.forEach(program => {
      if (program) {
        const searchItem: SearchData = {
          ProgramId: program.key,
          Phone: program.Call,
          IsProgram: true,
          Address: program.Address
        };
        const organization = this.organizations.find(o => o.key === program.OrganizationId);
        let organizationName;
        if (organization && organization.Name) {
          searchItem.OrganizationId = organization.key;
          organizationName = organization.Name.trim();
        }

        if (program.Summary) {
          // Concat org name with program summary and create tags
          const summaryName = organizationName ? `${organizationName} ${program.Summary}` : program.Summary;
          searchItem.SummaryTags = this.createTags(summaryName);
        }
        if (program.Description) {
          const descriptionName = organizationName ? `${organizationName} ${program.Description}` : program.Description;
          searchItem.DescriptionTags = this.createTags(descriptionName);
        }

        // Set display name
        if (program.Summary) {
          searchItem.DisplayName = `${organizationName}: ${program.Summary}`;
        } else if (program.Description) {
          searchItem.DisplayName = `${organizationName}: ${
            program.Description.length > 50
              ? program.Description.substring(0, 50 + 1) + '...'
              : program.Description
          }`;
        } else {
          searchItem.DisplayName = organizationName;
        }

        this.searchData.push(searchItem);
      }
    });
  }
}
