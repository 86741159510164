<h1 mat-dialog-title>{{ data.isNew ? textValues[0] : textValues[1] }}</h1>
<form [formGroup]="requestForm" (ngSubmit)="submitProgramRequest()">
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>{{ textValues[2] }}</mat-label>
      <input matInput formControlName="requesterName" cdkFocusInitial />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ textValues[3] }}</mat-label>
      <input matInput formControlName="requesterEmail" required />
      <mat-error *ngIf="requestForm.get('requesterEmail').invalid">{{
        getErrorMessage('requesterEmail')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="long-input">
      <mat-label>{{ textValues[4] }}</mat-label>
      <input matInput formControlName="name" [required]="data.isNew" />
      <mat-error *ngIf="requestForm.get('name').invalid">{{
        getErrorMessage('name')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="data.isNew" class="long-input">
      <mat-label>{{ textValues[5] }}</mat-label>
      <input
        matInput
        formControlName="organization"
        [required]="data.isNew"
        [matAutocomplete]="auto"
      />
      <mat-error *ngIf="requestForm.get('organization').invalid">{{
        getErrorMessage('organization')
      }}</mat-error>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOrganizations | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="long-input">
      <mat-label>{{ textValues[6] }}</mat-label>
      <input matInput formControlName="address" [required]="data.isNew" />
      <mat-error *ngIf="requestForm.get('address').invalid">{{
        getErrorMessage('address')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field class="long-input">
      <mat-label>{{ textValues[7] }}</mat-label>
      <input matInput formControlName="website" [required]="data.isNew" />
      <mat-error *ngIf="requestForm.get('website').invalid">{{
        getErrorMessage('website')
      }}</mat-error>
    </mat-form-field>

    <p class="long-input">{{ textValues[8] }}</p>
    <mat-form-field [ngClass]="!data.isNew ? 'long-input' : ''">
      <mat-label>{{ textValues[9] }}</mat-label>
      <input
        type="tel"
        matInput
        formControlName="phoneNumber"
        [required]="data.isNew"
      />
      <mat-error *ngIf="requestForm.get('phoneNumber').invalid">{{
        getErrorMessage('phoneNumber')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="data.isNew">
      <mat-label>{{ textValues[10] }}</mat-label>
      <input matInput formControlName="email" />
      <mat-error *ngIf="requestForm.get('email').invalid">{{
        getErrorMessage('email')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="data.isNew" class="long-input">
      <mat-label>{{ textValues[11] }}</mat-label>
      <mat-select formControlName="category" [required]="data.isNew">
        <mat-option *ngFor="let cat of categories" [value]="cat">
          {{ cat.Name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="!requestForm.get('category').value.Name">{{
        getErrorMessage('category')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field
      *ngIf="
        data.isNew &&
        requestForm.get('category').value &&
        getSubcategories().length > 0
      "
      class="long-input"
    >
      <mat-label>{{ textValues[12] }}</mat-label>
      <mat-select formControlName="subcategory">
        <mat-option *ngFor="let subcat of getSubcategories()" [value]="subcat">
          {{ subcat }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="data.isNew && isFoodOrHealth()">
      <mat-label>{{ textValues[13] }}</mat-label>
      <input matInput formControlName="startTime" />
    </mat-form-field>

    <mat-form-field *ngIf="data.isNew && isFoodOrHealth()">
      <mat-label>{{ textValues[14] }}</mat-label>
      <input matInput formControlName="endTime" />
    </mat-form-field>

    <mat-form-field class="long-input">
      <mat-label>{{ textValues[15] }}</mat-label>
      <textarea
        matInput
        formControlName="description"
        [required]="data.isNew"
      ></textarea>
      <mat-error *ngIf="requestForm.get('description').invalid">{{
        getErrorMessage('description')
      }}</mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="data.isNew" class="long-input">
      <mat-label>{{ textValues[16] }}</mat-label>
      <textarea matInput formControlName="otherInfo"></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <label class="bottom-error" *ngIf="!data.isNew && !hasValueChanged()">
    {{ textValues[17] }}
  </label>

  <div mat-dialog-actions>
    <button type="button" mat-button mat-dialog-close>
      {{ textValues[18] }}
    </button>
    <button
      type="submit"
      mat-button
      [disabled]="
        data.isNew
          ? requestForm.invalid
          : requestForm.invalid || !hasValueChanged()
      "
    >
      {{ textValues[19] }}
    </button>
  </div>
</form>
