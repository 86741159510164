import { Component, OnInit } from '@angular/core';
import { DataAccessorService } from '../../Services/data-accessor.service';
import {
  LocationNameMapper,
  LocationCodeMapper,
} from '../../Models/enums/location';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { Language } from 'src/app/Models/language.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.sass'],
})
export class LandingPageComponent implements OnInit {
  language: string;
  baseColor = '#2373b4';
  public displayText: Language;
  public languages: Language[] = [];

  entities: string[];

  selectedConnection: string;
  appLogo = '../assets/images/MyLNK-Logo-White.svg';
  public locationNameMapper = LocationNameMapper;

  constructor(private preferencesService: PreferencesService, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      // changing the language has some funny behavior, this 'fixes' that by allowing a reload
      return false;
  };

  }

  get dbConnections() {
    return DataAccessorService.dbConnections;
  }

  async ngOnInit() {
    this.entities = Object.keys(LocationNameMapper);
    this.entities.shift();

    this.selectedConnection = this.entities[0];
    DataAccessorService.dbLoaded = false;

    this.language = this.preferencesService.getLang();

    const result = await this.preferencesService.getLanguages();
    const values: Language[] = [];
    for (let r in result) {
      values.push(result[r]);
    }
    this.languages = values;
    this.displayText = result[this.language];
  }

  redirectToWebsite() {
    let port = window.location.port;
    if (port) {
      port = ':' + port;
    }
    let url = LocationCodeMapper[this.selectedConnection] + '.mylnk.app';
    if (
      window.location.hostname.startsWith('qa') ||
      window.location.hostname.startsWith('mylnk-qa')
    ) {
      url = 'qa.' + url;
    }

    if (window.location.hostname.endsWith('.testing')) {
      url = url.replace('.app', '.testing');
    }

    const language = this.preferencesService.getLang();
    this.preferencesService.setLocation(this.selectedConnection);
    window.location.href = `http://${url}${port}/categories?language=${language}`;
  }

  setLang(language: string) {
    this.preferencesService.setLang(language);
  }
}
