import { Component, Host, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetailView } from '../../Models/detailView.model';
import { Schedule } from '../../Models/schedule.model';
import { Breadcrumb } from '../../Models/breadcrumb.model';
import { Program } from '../../Models/program.model';
import { ProgramAccessorService } from '../../Services/program-accessor.service';
import { OrganizationAccessorService } from '../../Services/organization-accessor.service';
import { ScheduleAccessorService } from '../../Services/schedule-accessor.service';
import { PreferencesService } from 'src/app/Services/preferences.service';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';
import { ProgramRequestComponent } from '../program-request/program-request.component';
import { MatDialog } from '@angular/material/dialog';
import { ProgramRequestData } from 'src/app/Models/programRequestModalData.model';
import { CategoryAccessorService } from 'src/app/Services/category-accessor.service';
import { Category } from 'src/app/Models/category.model';

@Component({
  selector: 'app-program-view',
  templateUrl: './program-view.component.html',
  styleUrls: [
    '../../../assets/styles/programAndOrganization-view.component.sass',
  ],
})
export class ProgramViewComponent implements OnInit {
  detailView: DetailView;
  id: string;
  schedules: Schedule[];
  fromCategories = false;
  lang: string;
  categoryName: string;
  subcategoryName: string;

  constructor(
    public dialog: MatDialog,
    private categoryAccessor: CategoryAccessorService,
    private organizationAccessor: OrganizationAccessorService,
    private breadCrumbService: BreadcrumbService,
    private preferencesService: PreferencesService,
    private programAccessor: ProgramAccessorService,
    private router: Router,
    private route: ActivatedRoute,
    private scheduleAccessor: ScheduleAccessorService
  ) {}

  ngOnInit() {
    this.lang = this.preferencesService.getLang();

    this.route.queryParams.subscribe((params) => {
      this.fromCategories = params['frmCats'] === '1';
    });

    this.route.params.subscribe((params) => {
      this.id = params['id'];

      this.programAccessor.getProgramById(this.id).subscribe((program) => {
        this.programAccessor
          .getProgramById(this.id, this.lang)
          .subscribe((langProg) => {
            program.Summary = langProg ? langProg.Summary : program.Summary;
            program.Description = langProg
              ? langProg.Description
              : program.Description;
            const dispName = Program.getDisplayName(
              this.organizationAccessor,
              program,
              100,
              this.lang
            );
            this.detailView = {
              id: this.id,
              name: program.Summary,
              phone: program.Call,
              website: program.Website,
              address: program.Address,
              description: program.Description,
              displayName: dispName,
            };
            if (this.fromCategories) {
              this.breadCrumbService.addCrumb(new Breadcrumb(dispName, `/program/${this.id}`));
            }
          });

        this.scheduleAccessor
          .getSchedulesWhere((schedule) => schedule.Id === this.id)
          .subscribe((schedules) => {
            this.schedules = schedules;
          });

        this.categoryAccessor
          .getCategoryById(program.CategoryId)
          .subscribe((category: Category) => {
            this.categoryName = category.Name;
            if (
              category.Subcategories &&
              category.Subcategories[program.SubcategoryId]
            ) {
              this.subcategoryName =
                category.Subcategories[program.SubcategoryId].Name;
            } else {
              this.subcategoryName = '';
            }
          });
      });
    });
  }

  openDialog() {
    const name = this.detailView.name
      ? this.detailView.name
      : this.detailView.displayName;

    const data: ProgramRequestData = {
      isNew: false,
      address: this.detailView.address,
      description: this.detailView.description,
      id: this.detailView.id,
      name: name,
      phoneNumber: this.detailView.phone,
      website: this.detailView.website,
      category: this.categoryName,
      subcategory: this.subcategoryName,
    };

    this.dialog.open(ProgramRequestComponent, {
      width: '600px',
      data: data,
    });
  }
}
