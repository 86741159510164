import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.sass']
})

//PDF generation was something we were working on last summer but ended up shelving.
//If you do end up reimplimenting it you can grab the code from the admin site
export class PdfComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }

  public genPDF(type: string, bodySize: number) {
    // this.data.genPDF(type, bodySize);
  }
}
