import { Component, OnInit } from '@angular/core';
import { DataAccessorService } from './Services/data-accessor.service';
import { ThemeAccessorService } from './Services/theme-accessor.service';
import { Router, NavigationEnd } from '@angular/router';
import { PreferencesService } from './Services/preferences.service';
import { filter } from 'rxjs/operators';
import { Language } from './Models/language.model';
import { MatDialog } from '@angular/material/dialog';
import { ProgramRequestComponent } from './Components/program-request/program-request.component';
import { ProgramRequestData } from './Models/programRequestModalData.model';
import { Location, LocationNameMapper } from './Models/enums/location';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  public android = false;
  public apple = false;
  public appLogo = '../assets/images/MyLNK-Logo-White.svg';
  public appstoreImage = '../assets/images/apple.png';
  public appStoreLink: string;
  public baseColor = '#2373b4';
  public copyrightYear: number = new Date().getFullYear();
  public expandedHeader = true;
  public googlePlayImage = '../assets/images/android.png';
  public googlePlayLink: string;
  public hasLanguageSet = true;
  public landingPage = true;
  public language: string;
  public location: string;
  public menuText: Language;
  public showBreadcrumbs = false;
  public staticText: { string: Language };
  public hotlineId: string;
  public privacyText: string;
  public requestText: string;

  constructor(
    private preferencesService: PreferencesService,
    private themeAccessor: ThemeAccessorService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  public async ngOnInit() {
    this.language = this.preferencesService.getLang();
    switch (this.language) {
      case ('ar'):
        this.privacyText = 'الخصوصيه';
        this.requestText = 'طلب برنامج جديد';
        break;
      case ('es'):
        this.privacyText = 'Privacidad';
        this.requestText = 'Solicitar un nuevo programa';
        break;
      case ('vi'):
        this.privacyText = 'Sự riêng tư';
        this.requestText = 'Yêu cầu một chương trình mới';
        break;
      case ('uk'):
        this.privacyText = 'Приватність';
        this.requestText = 'Замовити нову програму';
        break;
      case 'prs':
        this.privacyText = 'حریم خصوصی';
        this.requestText = 'درخواست یک برنامه جدید';
        break;
      case 'ps':
        this.privacyText = 'د محرمیت تګلاره';
        this.requestText = 'د نوې کړنلارې غوښتنه';
        break;
      case 'ku':
        this.privacyText = 'تایبەتمەندی';
        this.requestText = 'داوای بەرنامەیەکی نوێ بکە';
        break;
      case 'kmr':
        this.privacyText = 'Veşarî';
        this.requestText = 'Bernameyeke nû daxwaz bike';
        break;
      default:
        this.privacyText = 'Privacy';
        this.requestText = 'Request a new Program';
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event) => {
        const language = this.router.url.split('language=')[1];
        if (language) {
          this.preferencesService.setLangSilent(language);
        }
        const location = window.location.href
          .replace('http://', '')
          .replace('https://', '')
          .replace('qa.', '');
        if (
          (!location.startsWith('mylnk') && location.endsWith('/home')) ===
          false
        ) {
          this.preferencesService.setLocationSilent(location.split('.')[0]);
        }

        this.location = this.preferencesService.getLocationDisplayName();

        this.expandedHeader =
          this.router.url === '/' || this.router.url === '/categories';
        this.landingPage = this.router.url.indexOf('/home') >= 0;

        this.language = this.preferencesService.getLang();
        if (!this.language) {
          this.hasLanguageSet = false;
          return;
        }

        this.staticText = await this.preferencesService.getLanguages();
        this.menuText = this.staticText[this.language];
      });

    [this.appStoreLink, this.googlePlayLink] = await Promise.all([
      this.themeAccessor.getSingleThemeElement('appStoreLink'),
      this.themeAccessor.getSingleThemeElement('googlePlayLink'),
    ]);

    if (/msie\s|trident\//i.test(window.navigator.userAgent)) {
      // If Internet Explorer, return version number
      alert(
        'Internet explorer is not supported and site functionality will be minimal. Please upgrade to Microsoft Edge or a similar browser for the best experience.'
      );
    }

    this.hotlineId = await this.themeAccessor.getSingleThemeElement('hotlineId');
    this.detectPlatform();
  }

  get dbLoaded() {
    return DataAccessorService.dbLoaded;
  }

  // match mobile platforms to load app banner
  detectPlatform() {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    ) {
      this.apple = true;
    } else if (navigator.userAgent.match(/Android/i)) {
      this.android = true;
    }
  }

  setLanguage() {
    this.preferencesService.setLangSilent('');
    const redirectUrl = this.router.url;
    this.router.navigate(['/set-language'], { queryParams: { redirectUrl } });
  }

  openDialog() {
    const data: ProgramRequestData = {
      isNew: true,
    };
    this.dialog.open(ProgramRequestComponent, {
      width: '600px',
      data: data,
    });
  }
}
