import { Injectable } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {ObjectCache} from '../Models/objectCache.model';
import {Category} from '../Models/category.model';
import {Observable} from 'rxjs';
import {DataAccessorService} from './data-accessor.service';
import {Schedule} from '../Models/schedule.model';
import {Program} from '../Models/program.model';
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleAccessorService extends DataAccessorService<Schedule>{

  constructor(private db: AngularFireDatabase, private ps: PreferencesService) {
    super(db, `entities/${ps.getLocation()}/Data/Scheduled`, new ObjectCache<Schedule>());
  }

  getSchedulesWhere(filter: Function, lang?: string): Observable<Schedule[]> {
    return super.getObjectsWhere(filter, lang);
  }

  getSchedules(lang?: string): Observable<Schedule[]> {
    return super.getObjects(lang);
  }

}
